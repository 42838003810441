export const getByParam = (list, param, value) => {
    if (list && param && value)
        for (let i = 0; i < list.length; i++)
            if (list[i][param] == value)
                return list[i];
    return null;
};

export const convertToLegacyDate = (date) => {
    var monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthName[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' 00:00:00 AM';
};

export const convertLegacyToDate = (legacyDate) => {
    // var monthName = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // return monthName[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' 00:00:00 AM';
};

export const getUrlOrPathForMenuItem = (menuItem) => {
    if (menuItem.targetType && menuItem.targetType === 'ExternalUrl') return { 'url': menuItem.value }
    else return { 'path': menuItem.fullPath }
};

export const isValidEmail = (email) => {
    const r = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return r.test(email)
    // "phoneNumberRegEx": /^(?=.*?[1-9])[0-9()-+//]+$/,
};

export const isValidPhone = (phone) => {
    const r = /^(?=.*?[1-9])[0-9()-+//]+$/;
    return r.test(phone)
};