import { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/hu';
import 'moment-business-days';

import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import hu from 'date-fns/locale/hu';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('hu', hu);
moment.locale('hu');

const durationDays = 130;

export default function MPTIACalculator(props) {
    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(new Date(moment(Date.now()).businessAdd(durationDays)));

    setDefaultLocale('hu');

    // const now = Date.now();

    moment.updateLocale('hu', {
        workingWeekdays: [1, 2, 3, 4, 5],
        holidayFormat: 'YYYY-MM-DD',
        holidays: ['2023-01-01', '2023-03-15', '2023-04-07', '2023-04-09', '2023-04-10', '2023-05-01', '2023-05-28', '2023-05-29', '2023-08-20', '2023-10-23', '2023-11-01', '2023-12-25', '2023-12-26'],
        forcedBusinessDays: [],
        forcedBusinessDaysFormat: 'YYYY-MM-DD',
    });

    const boxClassName = 'relative bg-primary flex flex-col gap-2 p-8 shadow-xl flex-1 justify-between';
    const titleClassName = 'font-medium text-sm sm:text-xl lg:text-xl xl:text-lg 2xl:text-2xl text-white font-display';
    const leadClassName = 'text-white/90 text-xs';
    const pickerClassName = 'px-2 py-4 bg-primary-dark bg-primary text-white shadow outline-none text-center w-full cursor-pointer hover:ring-4 ring-accent shadow-3xl transition';
    const dateClassName = 'text-lg sm:text-2xl text-center text-white font-bold';

    return (
        <div className='w-full flex flex-col lg:flex-row items-stretch justify-center gap-8 sm:gap-10 xl:gap-12 lg:gap-10'>

            <div className={boxClassName}>

                <div className='flex gap-6 items-center'>

                    <div className='w-[40px] aspect-[65/78] bg-contain bg-no-repeat' style={{ backgroundImage: 'url(/assets/images/etc/calculator-left.png)' }} />

                    <div className='flex-1 flex flex-col gap-2'>
                        <div className={titleClassName}>Amennyiben a visszaváltási megbízás beadásától előre szeretne számolni</div>
                    </div>

                </div>

                <div className={leadClassName + ' py-4'}>Kérjük, adja meg azt a dátumot, amikor benyújtja a szükséges visszaváltási megbízást, és a rendszer kiszámolja, mikor tud hozzáférni a megtakarításához.</div>

                <div className='flex flex-col bg-white/10 text-center'>
                    <DatePicker dateFormat="P" className={pickerClassName} locale='hu' selected={startDate} onChange={(date) => setStartDate(date)} />
                    <div className='flex-col p-4'>
                        <div className={dateClassName}>{moment(startDate).businessAdd(durationDays).format('LL')}</div>
                        <div className={leadClassName}>Ekkor lesz a számláján a megtakarítása.</div>
                    </div>
                </div>

            </div>

            <div className={boxClassName}>

                <div className='flex gap-6 items-center'>

                    <div className='w-[50px] aspect-[75/75] bg-contain bg-no-repeat' style={{ backgroundImage: 'url(/assets/images/etc/calculator-right.png)' }} />

                    <div className='flex-1 flex flex-col gap-2'>
                        <div className={titleClassName}>Amennyiben az adott időpontban szeretne hozzáférni a megtakarításához</div>
                    </div>

                </div>

                <div className={leadClassName + ' py-4'}>Kérjük, adja meg azt a dátumot, amikor hozzá szeretne férni a megtakarításához, és a rendszer kiszámolja, mikor kell benyújtani a szükséges visszaváltási megbízást.</div>

                <div className='flex flex-col bg-white/10 text-center'>
                    <DatePicker dateFormat="P" className={pickerClassName} locale='hu' selected={endDate} onChange={(date) => setEndDate(date)} />
                    <div className='flex-col p-4'>
                        <div className={dateClassName}>{moment(endDate).businessSubtract(durationDays).format('LL')}</div>
                        <div className={leadClassName}>Legkésőbb eddig javasolt beadni a visszaváltási megbízást.</div>
                    </div>
                </div>

            </div>

        </div>
    );
}