import { useState, useEffect } from 'react';

import PageTitle from 'elements/PageTitle';
import { DocumentDownload } from 'pages/DocumentDownloadPage';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function SustainabilityPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        content: getValueFromParameterMap(page, 'content'),
        fund: getValueFromParameterMap(page, 'fund'),
        fundTitle: getValueFromParameterMap(page, 'fund-title'),
        fundContent: getValueFromParameterMap(page, 'fund-content'),
    }

    useEffect(() => { setHeaderDark(); }, []);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col'>

            <PageTitle className='flex-1' titleClassName='xl:w-min' title={_data.title} content={_data.content} />

            <PageTitle className='flex-1 bg-white' paddingClassName='py-page-title-row-small-offset' titleClassName='text-primary' title={_data.fundTitle} subTitle='Befektetés egy fenntarthatóbb jövő jegyében' subTitleClassName='text-primary' content={_data.fundContent} contentClassName='text-primary' contentChildren={<a href={_data.fund && getValueFromParameterMap(_data.fund, 'page')} className='self-start rounded-button-primary'>Tovább az alap adataihoz</a>} />

            <Documents data={page} />

        </NBossCMSPage>
    );
}

function Documents(props) {
    const _data = {
        publicationsTitle: getValueFromParameterMap(props.data, 'publications-title'),
        documentList: getValueFromParameterMap(props.data, 'document-list'),
    }
    return (
        <div className='w-full bg-white'>

            {_data.documentList && <DocumentDownload documentList={_data.documentList} flat />}

        </div>
    );
}