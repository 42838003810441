import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { NBossCMSPage } from 'cms/NBossCMS.js';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function Error404Page(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    let _data = {
        // background: getValueFromParameterMap(page, 'background-image') && getValueFromParameterMap(page, 'background-image').downloadUrl,
        background: 'https://images.unsplash.com/photo-1600695268275-1a6468700bd5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80',
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='relative w-full flex flex-col items-center justify-center flex-1'>

            <div className='absolute grayscale w-full h-full bg-no-repeat bg-center bg-cover mask opacity-0' style={{ backgroundImage: 'url(' + _data.background + ')' }} />


            <div className='relative w-full h-full flex items-center justify-center overflow-hidden'>

                <ErrorMessage />

            </div>

        </NBossCMSPage>
    );
}

function ErrorMessage(props) {
    return (
        <div className={'flex lg:items-center flex-col lg:flex-row justify-center sm:gap-8 text-white p-6 md:pl-10'}>

            <h1 className='font-display text-accent text-6xl sm:text-8xl'>404</h1>

            <div className='flex flex-col gap-4'>
                <div className='text-base sm:text-2xl font-medium'>A keresett oldal nem található.</div>
                <div className='text-xs md:text-sm'>
                    <div className='inline md:block'>Elnézést kérünk, ez az oldal jelenleg nem elérhető vagy nem létezik. </div>
                    <div className='inline md:block'>Kérjük, ellenőrizze a címet vagy látogasson el <Link to='/'>főoldalunkra</Link>.</div>
                </div>
            </div>

        </div>
    );
}