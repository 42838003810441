import DataField from 'elements/DataField';

const getGridColumnStyle = (column) => { return { gridTemplateColumns: 'repeat(' + column + ', minmax(0, 1fr))' } };

export default function Table(props) {
    if (props.data)
        return (
            <div className={'w-full flex flex-col overflow-hidden ' + (props.className || '')} style={getGridColumnStyle(props.data.config.fields.length)}>
                <Heading config={props.data.config} />
                {props.data.list.map((item, index) => (<Row key={index} data={item} config={props.data.config} />))}
            </div>
        );
    return null;
}

function Heading(props) {
    const getConfig = (i) => {
        // let config = props.config.fields[i];
        let config = JSON.parse(JSON.stringify(props.config.fields[i]))
        config.strong = true;
        config.type = null;
        return config;
    };

    return (
        <div className='w-full grid' style={getGridColumnStyle(props.config.fields.length)}>
            {[...Array(props.config.fields.length)].map((item, index) => (<Cell key={index} className='text-sm md:text-base flex flex-col justify-end' value={props.config.fields[index].label} config={getConfig(index)} />))}
        </div>
    );
}

function Row(props) {
    const getConfig = (i) => { let config = props.config.fields[i]; config.strong = false; return config; };

    return (
        <div className={'w-full grid ' + (props.config.reverseBackground ? 'even:bg-neutral-100' : 'odd:bg-neutral-100')} style={getGridColumnStyle(props.config.fields.length)}>
            {[...Array(props.config.fields.length)].map((item, index) => (<Cell key={index} className={'text-xxs md:text-sm ' + (props.config.rowSeparation ? 'odd:bg-neutral-100 even:bg-white' : '')} value={props.data[props.config.fields[index].key]} config={getConfig(index)} />))}
        </div>
    );
}

function Cell(props) {
    // console.log(props.config);
    return (<DataField className={'px-4 first:pl-8 last:pr-8 py-2 md:py-3 ' + (props.className || '')} value={props.value} config={props.config} />);
}