import DataField from 'elements/DataField';

export default function DataLister(props) {
    return (
        <div className={'w-full flex flex-col shadow-2xl ' + (props.className || '')}>

            {props.data.map((item, index) => (<div key={index} className={'flex-1 w-full flex flex-col gap-1 md:gap-4 items-center md:flex-row text-sm even:bg-neutral-100 px-8 py-2 md:py-3 ' + (props.itemClassName || '') + ' ' + (item.strong ? 'font-bold' : '')}>
                <div className={'md:w-[45%] ' + (item.label.length > 28 ? 'md:text-xs' : '')}>{item.label}</div>
                <div className='flex-1 font-bold text-center md:text-left'>
                    <DataField value={item.value} config={item.config} />
                </div>
            </div>))}

        </div>
    );
}