import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';

import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';

const getDefaultMenu = (currentLocation) => {
    let pathName = currentLocation.pathname;
    const pathParts = pathName.substring(1).split('/');
    const basePath = pathParts[0];
    const mainMenu = getValueFromParameterMap(getWebsite().globalContent, 'main-menu');
    let baseMenu = mainMenu;
    for (let i = 0; i < mainMenu.itemList.length; i++) if (mainMenu.itemList[i].path === basePath) baseMenu = mainMenu.itemList[i]
    return baseMenu;
}

export default function PageTitle(props) {
    return (
        <div className={'w-full flex flex-col gap-6 items-center px-site ' + (props.colorClassName ? props.colorClassName : 'bg-primary text-white') + ' ' + (props.paddingClassName ? props.paddingClassName : 'py-page-title') + ' ' + (props.className || '')}>

            {props.breadcrumb && <div className='w-full max-w-boxed px-content'>
                <PageTitleBreadcrumbList customChildren={props.customBreadcrumbChildren} />
            </div>}

            <div className='w-full max-w-boxed flex flex-col gap-10 px-content'>

                {(props.type && props.type === 'title-only') && <TitleOnlyPageTitle {...props} />}
                {(!props.type || props.type === 'split') && <SplitPageTitle {...props} />}

            </div>

            {props.bottomChildren && <div className='w-full max-w-boxed px-content mt-6'>{props.bottomChildren}</div>}

        </div>
    );
}

function TitleOnlyPageTitle(props) {
    return (
        <>
            <div className='flex flex-col gap-2'>
                {props.title && <h1 className={'text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl font-display ' + (props.titleClassName || '')}>{props.title}</h1>}
                {props.subTitle && <h2 className={'2xl:text-lg ' + (props.subTitleClassName || '')}>{props.subTitle}</h2>}
            </div>

            {props.contentChildren && <div>{props.contentChildren}</div>}
        </>
    );
}

function SplitPageTitle(props) {
    const splitPageTitleClassName = (props.title && props.title.length > 36) ? 'text-3xl sm:text-4xl lg:text-5xl xl:text-3xl 2xl:text-4xl' : 'text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl';

    return (
        <div className={'flex flex-col xl:flex-row gap-10 xl:gap-16 ' + (props.innerClassName || '')}>

            <div className='flex-1 flex flex-col gap-2'>
                {props.title && <h1 className={splitPageTitleClassName + ' font-display ' + (props.titleClassName || '')}>{props.title}</h1>}
                {props.subTitle && <h2 className={'2xl:text-lg ' + (props.subTitleClassName || '')}>{props.subTitle}</h2>}
                {props.titleChildren && <div className={props.subTitle ? 'pt-4 xl:pt-6' : 'pt-2'}>{props.titleChildren}</div>}
            </div>

            <div className={'flex flex-col gap-8 items-center ' + (!props.disableContentPadding ? (props.subTitle ? 'xl:pt-[68px]' : 'xl:pt-[50px]') : '') + ' ' + (props.splitClassName ? props.splitClassName : 'xl:w-[70%]')}>
                {props.highlightSubTitle && <div className='w-full text-3xl sm:text-3xl lg:text-3xl 2xl:text-3xl font-display -mt-2'>{props.highlightSubTitle}</div>}
                {props.content && <div className={'text-xs xl:text-sm leading-relaxed xl:leading-relaxed paragraph-gap  ' + (props.contentClassName || '')} dangerouslySetInnerHTML={{ __html: props.content }} />}
                {props.contentChildren && props.contentChildren}
            </div>

        </div>
    );
}

export function PageTitleMenuList(props) {
    return (
        <div className={'flex items-center md:items-start flex-wrap gap-6 ' + (props.stayRow ? '' : 'flex-col md:flex-row ')}>
            {props.children}
        </div>
    );
}

export function PageTitleMenuListItem(props) {
    return (
        <div className={'pb-2 text-[11px] text-center uppercase font-semibold transition ' + (props.active ? 'text-accent border-b-2 border-accent' : 'text-white hover:text-accent')}>{props.label}</div>
    );
}

export function PageTitleSubMenuList(props) {
    let location = useLocation();

    return (
        <PageTitleMenuList>
            {props.includeParent && <PageTitleSubMenuItem data={getDefaultMenu(location)} end />}
            {getDefaultMenu(location) && getDefaultMenu(location).childList.map((item) => (<PageTitleSubMenuItem key={item.id} data={item} />))}
        </PageTitleMenuList>
    );
}

export function PageTitleSubMenuItem(props) {
    return (
        <NavLink to={props.data.fullPath} end={props.end} className={' ' + (props.className || '')}>
            {({ isActive }) => <PageTitleMenuListItem label={props.data.title} active={isActive} />}
        </NavLink>
    );
}

export function PageTitleBreadcrumbList(props) {
    let location = useLocation();
    const navigate = useNavigate();

    return (
        <div className='flex divide-x divide-black/10'>
            {props.customChildren ? props.customChildren : <button onClick={() => navigate(-1)} className='page-title-breadcrumb'>‹ Vissza</button>}
            <PageTitleBreadcrumbItem data={getDefaultMenu(location)} />
            {/* {getDefaultMenu(location) && getDefaultMenu(location).childList.map((item) => (<PageTitleBreadcrumbItem key={item.id} data={item} />))} */}
        </div>
    );
}

export function PageTitleBreadcrumbItem(props) {
    return (
        <Link to={props.data.fullPath} className='page-title-breadcrumb '>{props.data.title}</Link>
    );
}