import { Link } from 'react-router-dom';

import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';

export default function Footer(props) {
    let _data = {
        menu: getValueFromParameterMap(getWebsite().footerContent, 'menu'),
        copyright: getValueFromParameterMap(getWebsite().footerContent, 'copyright'),
    };

    return (
        <footer className={'w-full flex items-center justify-center py-16 px-site bg-secondary text-white ' + (props.className || '')}>

            <div className='w-full max-w-boxed flex flex-col sm:flex-row md:flex-col lg:flex-row items-center gap-16 lg:gap-0'>

                <div className='order-last sm:order-first md:order-last lg:order-first flex flex-col items-center gap-6'>
                    <Link to='/' className='relative w-[130px] md:w-[104px] group'>
                        <img className='w-full' src='/assets/images/logo/logo-white.svg' />
                        <img className='absolute top-0 left-0 w-full bg-secondary opacity-0 group-hover:opacity-100 transition' src='/assets/images/logo/logo-accent.svg' />
                    </Link>
                    <div className='lg:hidden text-xxs uppercase'>{_data.copyright}</div>
                </div>

                <div className='relative w-full flex flex-col items-start sm:items-end md:items-center lg:items-end gap-4'>
                    <Menu data={_data.menu} />
                    <div className='hidden lg:block absolute bottom-0 right-0 h-0'>
                        <div className='mt-4 text-xxs uppercase'>{_data.copyright}</div>
                    </div>
                </div>

            </div>

        </footer>
    );
}

function Menu(props) {
    const itemClassName = 'hover:text-accent text-xs md:text-[10px] 2xl:text-xs font-semibold tracking-wider lg:tracking-normal xl:tracking-wider uppercase transition-colors md:text-center'
    return (
        <div className='flex flex-col md:flex-row md:items-center gap-10 md:gap-6 2xl:gap-8'>

            {props.data && props.data.itemList.map((item) => (process.env.REACT_APP_DOMAIN_NAME === 'premium-vagyonkezeles.hu' ? <a target='_blank' className={itemClassName} href={'https://granitalapkezelo.hu' + item.fullPath}>{item.title}</a> : <Link key={item.id} to={item.fullPath} className={itemClassName}>{item.title}</Link>))}

        </div>
    );
}

export function SubFooter(props) {
    return (
        <div className='w-full flex flex-col items-center bg-secondary text-white px-site py-16'>

            <div className='w-full max-w-boxed flex flex-col md:flex-row gap-16 px-contentt'>

                {props.children}

            </div>

        </div>
    );
}

export function SubFooterButtonItem(props) {
    return (
        <Link to={props.path} className='flex flex-col gap-10'>
            <div className='font-display md:font-sans text-3xl md:text-lg md:font-medium px-4 md:px-0'>{props.title}</div>
            <button className='rounded-button-accent-reverse'>{props.label}</button>
        </Link>
    );
}
