import { useState, useEffect } from 'react';

import PageTitle, { PageTitleSubMenuList } from 'elements/PageTitle';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function AwardsPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        awardYearList: getValueFromParameterMap(page, 'award-year-list') && getValueFromParameterMap(page, 'award-year-list').contentList,
    }

    useEffect(() => { setHeaderDark(); }, []);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col'>

            <PageTitle className='py-page-title-small-offset' title={_data.title} subTitle={_data.subTitle} type='title-only' />

            <div className='flex-1 w-full bg-neutral-50 flex flex-col items-center px-site-mobile md:pb-16 text-primary'>

                <div className={'w-full max-w-boxed px-content page-title-content-offset'}>

                    <div className='flex flex-col sm:gap-8 xl:gap-16 divide-y-4 sm:divide-y-0 divide-accent'>
                        {_data.awardYearList && _data.awardYearList.map((item) => <AwardYear key={item.id} data={item} />)}
                    </div>

                </div>

            </div>

        </NBossCMSPage>
    );
}

function AwardYear(props) {
    const _data = {
        year: getValueFromParameterMap(props.data, 'year'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        award: getValueFromParameterMap(props.data, 'award') && getValueFromParameterMap(props.data, 'award').contentList,
    }

    return (
        <div className='w-full bg-white shadow-3xl flex flex-col'>

            <div className='bg-primary-dark text-white flex flex-col gap-4 p-8 border-t-4 border-accent'>
                <div className=' font-display text-5xl'>{_data.year}</div>
                <div className='text-sm' dangerouslySetInnerHTML={{ __html: _data.lead }} />
            </div>

            <div className='bg-white p-8 flex flex-col gap-4'>
                {_data.award && _data.award.map((item) => <Award key={item.id} data={item} />)}
            </div>

        </div>
    );
}

function Award(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        description: getValueFromParameterMap(props.data, 'description'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
    }

    return (
        <div className='w-full shadow-3xl flex flex-col lg:flex-row overflow-hidden'>

            {_data.image && <div className='bg-white p-4 flex items-center justify-center'>
                <div className='bg-contain bg-no-repeat w-[320px] aspect-video' style={{ backgroundImage: 'url(' + _data.image + ')' }} />
            </div>}

            <div className='bg-white text-primary flex-1 px-8 xl:px-12 py-8 flex flex-col gap-3 justify-center shadow-3xl'>
                <div className='font-display text-xl sm:text-2xl xl:text-3xl font-medium'>{_data.title}</div>
                <div className='text-sm xl:text-base' dangerouslySetInnerHTML={{ __html: _data.description }} />
            </div>

        </div>
    );
}