import { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { FaLocationPin } from 'react-icons/fa6';

import PageTitle, { PageTitleSubMenuList } from 'elements/PageTitle';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

import mapConfig from 'config/mapConfig.js';

export default function ContactPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
    }

    useEffect(() => { setHeaderDark(); }, []);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col'>

            <PageTitle className='py-page-title-no-offset' title={_data.title} type='title-only' bottomChildren={<PageTitleSubMenuList includeParent />} />

            {page && <Map data={page} />}

            {page && <DataList data={page} />}

        </NBossCMSPage>
    );
}

function Map(props) {
    const _data = {
        mapDataLat: getValueFromParameterMap(props.data, 'map-data-lat') && parseFloat(getValueFromParameterMap(props.data, 'map-data-lat')),
        mapDataLng: getValueFromParameterMap(props.data, 'map-data-lng') && parseFloat(getValueFromParameterMap(props.data, 'map-data-lng')),
    }

    return (
        <div className='relative w-full h-96'>

            <GoogleMapReact
                key='map'
                bootstrapURLKeys={{ key: 'AIzaSyD1luTKqk_RQjga3FZ2vTeFJhms3nDrjSg', language: 'hu-HU', region: 'hu', }}
                options={map => ({
                    disableDefaultUI: true,
                    styles: mapConfig.styles,
                })}
                defaultCenter={{ lat: _data.mapDataLat, lng: _data.mapDataLng }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={15}
                disableDefaultUI={true}>

                <div className='w-[10000%] h-[10000%] -translate-x-1/2 -translate-y-1/2 bg-primary-dark/60'></div>

                <div className='relative w-0 h-0 flex flex-col items-center z-10' lat={_data.mapDataLat} lng={_data.mapDataLng}>
                    <FaLocationPin className='absolute bottom-0 text-accent text-5xl' />
                </div>

            </GoogleMapReact>

        </div>
    );
}

function DataList(props) {
    const _data = {
        address: getValueFromParameterMap(props.data, 'address'),
        phone: getValueFromParameterMap(props.data, 'phone'),
        fax: getValueFromParameterMap(props.data, 'fax'),
        email: getValueFromParameterMap(props.data, 'email'),
        taxNumber: getValueFromParameterMap(props.data, 'tax-number'),
        companyName: getValueFromParameterMap(props.data, 'company-name'),
        companyRegistrationNumber: getValueFromParameterMap(props.data, 'company-registration-number'),
        statisticalNumber: getValueFromParameterMap(props.data, 'statistical-number'),
        activityType: getValueFromParameterMap(props.data, 'activity-type'),
        shareholders: getValueFromParameterMap(props.data, 'shareholders'),
    }

    let dataList = [
        { 'label': 'Székhely cím', 'value': _data.address },
        { 'label': 'Telefonszám', 'value': _data.phone, 'href': 'tel:' + _data.phone },
        { 'label': 'Faxszám', 'value': _data.fax, 'href': 'tel:' + _data.fax },
        { 'label': 'E-mail cím', 'value': _data.email, 'href': 'mailto:' + _data.email },
        { 'label': 'Cégnév', 'value': _data.companyName },
        { 'label': 'Cégjegyzékszám', 'value': _data.companyRegistrationNumber },
        { 'label': 'Adószám', 'value': _data.taxNumber },
        { 'label': 'Statisztikai számjel', 'value': _data.statisticalNumber },
        { 'label': 'Tevékenységi körök', 'value': _data.activityType },
        { 'label': 'Részvényesek', 'value': _data.shareholders },
    ];

    return (
        <div className='w-full flex flex-col items-center bg-neutral-50 px-site py-site'>

            <div className='w-full max-w-boxed flex flex-col gap-8 lg:gap-16 px-content'>

                <div className='w-full font-display text-4xl text-primary'>Általános információk</div>

                <div className='w-full flex flex-col'>
                    {dataList.map((item, index) => (<div key={index} className='w-full flex flex-col text-primary px-0 sm:px-8 lg:px-16 xl:px-28'>
                        <div className='w-full flex flex-col md:flex-row py-8 lg:py-12'>
                            <div className='flex-1 text-xl font-medium'>{item.label}</div>
                            {!item.href && <div className='flex-1 disc-list text-sm md:text-base' dangerouslySetInnerHTML={{ __html: item.value }} />}
                            {item.href && <a className='flex-1 hover:text-accent text-sm md:text-base' href={item.href}>{item.value}</a>}
                        </div>
                        <div className='w-16 h-px bg-neutral-200' />
                    </div>))}
                </div>

            </div>

        </div>
    );
}