import { useState, useEffect } from 'react';
import { BsFiletypePdf } from 'react-icons/bs';
import { TfiPlus, TfiMinus } from 'react-icons/tfi';

import PageTitle, { PageTitleSubMenuList } from 'elements/PageTitle';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { getSubFolders, getFolderFiles } from 'utils/LegacyUtils';
import { getByParam } from 'utils/GenericUtils';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function DocumentDownloadPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();


    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        folder: getValueFromParameterMap(page, 'folder'),
        legacyFolderId: getValueFromParameterMap(page, 'legacy-folder-id'),
        documentList: getValueFromParameterMap(page, 'document-list'),
    }

    useEffect(() => { setHeaderDark(); }, []);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col'>

            <PageTitle className={_data.documentList ? 'py-page-title-small-offset' : 'py-page-title-no-offset'} title={_data.title} type='title-only' bottomChildren={<PageTitleSubMenuList includeParent />} />

            {page && <DocumentDownload legacyFolderId={_data.legacyFolderId} documentList={_data.documentList} subTitle={_data.subTitle} />}

            {/* {_data.legacyFolderId && <>
                <FolderSelect selectTitle={_data.subTitle} folderList={folderList} folder={folder} onFolderChange={(value) => setFolder(value)} />
                {folder !== '' && <SubFolderList folder={getByParam(folderList, 'id', folder)} />}
            </>}

            {_data.documentList && <SubFolderList files={_data.documentList} />} */}

        </NBossCMSPage>
    );
}

export function DocumentDownload(props) {
    const [folderList, setFolderList] = useState([]);
    const [folder, setFolder] = useState('');
    const [flat, setFlat] = useState(false);

    useEffect(() => {
        if (props.legacyFolderId) {
            setFolder('');
            getSubFolders(props.legacyFolderId, (value) => {
                if (value.length !== 0) {
                    setFlat(false);
                    getSubFolders(props.legacyFolderId, (value2) => { setFolderList(value2) });
                } else {
                    setFlat(true);
                }
            });
        }
    }, [props.legacyFolderId]);

    return (
        <>
            {props.legacyFolderId && <>

                {flat ?

                    <SubFolderList folder={{ id: props.legacyFolderId }} flat />

                    :

                    <>
                        <FolderSelect selectTitle={props.subTitle} folderList={folderList} folder={folder} onFolderChange={(value) => setFolder(value)} />
                        {folder !== '' && <SubFolderList folder={getByParam(folderList, 'id', folder)} />}
                    </>
                }

            </>}

            {props.documentList && <SubFolderList files={props.documentList} flat={props.flat} />}

        </>
    );
}

function FolderSelect(props) {
    return (
        <div className='w-full flex-1 bg-primary-dark flex flex-col items-center justify-center pt-16 md:pt-36 pb-24 md:pb-36 px-site' >

            <div className='w-full max-w-boxed px-content flex flex-col gap-6'>

                <div className='text-white text-sm md:text-lg'>{props.selectTitle ? props.selectTitle : 'Letölthető dokumentumok'}</div>

                <select className='w-full generic-select' value={props.folder} onChange={(e) => props.onFolderChange(e.target.value)}>
                    <option value='' disabled selected hidden>Kérjük, válasszon</option>
                    {props.folderList && props.folderList.map((item) => <option key={item.id} value={item.id} className='generic-select-option'>{item.name}</option>)}
                </select>

            </div>

        </div>
    );
}

function SubFolderList(props) {
    const [subFolderList, setSubFolderList] = useState([]);

    useEffect(() => {
        if (props.folder) {
            getSubFolders(props.folder.id, (value) => {
                if (value.length > 0)
                    setSubFolderList(value);
                else
                    setSubFolderList([props.folder]);
            });
        }
    }, [props.folder]);

    useEffect(() => {
        if (props.files)
            setSubFolderList([props.files]);
    }, [props.files]);

    return (
        <div className={'w-full flex-1 flex flex-col items-center px-site pb-8 md:pb-16 text-primary ' + (props.flat ? 'bg-primary' : 'bg-neutral-50')}>

            <div className='w-full max-w-boxed page-title-content-offset-mobile flex flex-col gap-6'>

                {subFolderList && subFolderList.map((item) => <FileList key={item.id} folder={item} openable={subFolderList.length > 1} flat={props.flat} />)}

            </div>

        </div>
    );
}

function FileList(props) {
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (props.folder) {
            if (props.folder.contentType)
                setFileList(getValueFromParameterMap(props.folder, 'document-list').contentList);
            else
                getFolderFiles(props.folder.id, (value) => { setFileList(value) });
        }
    }, [props.folder]);

    const getTitle = () => {
        let title = 'Letölthető dokumentumok';
        if (props.folder)
            if (props.folder.contentType) {
                if (getValueFromParameterMap(props.folder, 'public-title'))
                    title = getValueFromParameterMap(props.folder, 'public-title');
                else
                    title = getValueFromParameterMap(props.folder, 'title');
            } else if (props.folder.name)
                title = props.folder.name;
        return title;
    };

    return (
        <OpenableGroup title={getTitle()} openable={props.openable} flat={props.flat}>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 floating-card-grid-gap-smallest'>
                {fileList && fileList.map((item) => <FileItem key={item.id} data={item} flat={props.flat} />)}
            </div>
        </OpenableGroup>
    );
}

function FileItem(props) {
    const getHref = () => {
        let href = '/';
        if (getValueFromParameterMap(props.data, 'document')) href = getValueFromParameterMap(props.data, 'document').downloadUrl;
        else if (getValueFromParameterMap(props.data, 'external-url')) href = getValueFromParameterMap(props.data, 'external-url');
        else if (props.data.id) href = 'https://www.diofaalapkezelo.hu/backings/diofaFileRepository/getFileWithForceDownload?id=' + props.data.id;
        return href;
    };

    const getLabel = () => {
        let label = 'Dokumentum';
        if (props.data.name) label = props.data.name;
        if (getValueFromParameterMap(props.data, 'title')) label = getValueFromParameterMap(props.data, 'title');
        return label;
    };

    return (
        <a href={getHref()} className='bg-white hover:bg-primary text-primary hover:text-white floating-card p-6 flex flex-row gap-4 items-center transition'>

            <BsFiletypePdf className='text-4xl' />

            <div className='flex-1 text-[13px] font-semibold line-clamp-3'>{getLabel()}</div>

        </a>
    );
}

export function OpenableGroup(props) {
    const [open, setOpen] = useState(props.openable ? (props.open ? true : false) : true);

    return (
        <div className={'w-full flex flex-col gap-6 floating-card-padding ' + (props.flat ? 'bg-neutral-50' : 'floating-card')}>

            <button className='flex items-center gap-6 group' onClick={() => props.openable ? setOpen(!open) : null} disabled={!props.openable}>
                {props.title && <div className={'flex-1 text-left text-lg font-bold ' + (props.flat ? 'text-primary' : 'text-primary')}>{props.title}</div>}
                {props.openable && (open ? <TfiMinus className='group-hover:rotate-180 transition' /> : <TfiPlus className='group-hover:rotate-90 transition' />)}
                {/* {open ? <TfiMinus className='group-hover:rotate-180 transition' /> : <TfiPlus className='group-hover:rotate-90 transition' />} */}
            </button>

            {/* {props.openable && */}

            {open && props.children}

        </div>
    );
}