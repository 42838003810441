import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { TfiPlus, TfiMinus } from 'react-icons/tfi';

import { DataGrid } from 'pages/FundPage';
import PageTitle from 'elements/PageTitle';
import { SubFooter, SubFooterButtonItem } from 'components/Footer';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import CmsFrontendControl from 'services/CmsFrontendControl.js';
import { useWebsite } from 'providers/WebsiteContextProvider';

const typeMobileInsetClassName = 'px-8 md:px-0'

const getCategories = (data) => {
    let categoryList = [];
    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const category = getValueFromParameterMap(data[i], 'category');
            let has = false;
            for (let j = 0; j < categoryList.length; j++) if (getValueFromParameterMap(categoryList[j], 'key') === getValueFromParameterMap(category, 'key')) has = true;
            if (!has) categoryList.push(category);
        }
    }
    // categoryList.reverse();
    return categoryList;
};

const getTypeListByCategory = (data, category) => {
    let _list = [];
    for (let i = 0; i < data.length; i++) {
        if (category.indexOf(getValueFromParameterMap(getValueFromParameterMap(data[i], 'category'), 'key')) > -1) {
            const typeKey = getValueFromParameterMap(getValueFromParameterMap(data[i], 'type'), 'key');
            let index = -1; for (let j = 0; j < _list.length; j++) if (getValueFromParameterMap(_list[j].type, 'key') === typeKey) index = j;
            if (index < 0) _list.push({ type: getValueFromParameterMap(data[i], 'type'), list: [data[i]] });
            else _list[index].list.push(data[i]);
        }
    }
    return _list;
};

export default function FundListPage(props) {
    const [page, setPage] = useState(null);
    const [fundList, setFundList] = useState(null);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    const filterPublic = (list) => {
        let _list = [];
        for (let i = 0; i < list.length; i++) if (getValueFromParameterMap(list[i], 'public')) _list.push(list[i]);
        return _list;
    };

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        content: getValueFromParameterMap(page, 'content'),
        // fundList: getValueFromParameterMap(page, 'fund-list') && filterPublic(getValueFromParameterMap(page, 'fund-list').contentList),
        fundList: getValueFromParameterMap(page, 'fund-list-manual') && filterPublic(getValueFromParameterMap(page, 'fund-list-manual')),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col items-center text-white'>

            <PageTitle className='flex-1' titleClassName='xl:w-min' title={_data.title} content={_data.content} />

            {_data.fundList && <List data={_data.fundList} />}

            <SubFooter>
                <SubFooterButtonItem title='Fogalomtár' label='Ugrás a Fogalomtárhoz' path='/befektetesi-alapok/fogalomtar' />
                <SubFooterButtonItem title='Hozamkalkulátor' label='Ugrás a Hozamkalkulátorhoz' path='/befektetesi-alapok/hozamkalkulator' />
                <SubFooterButtonItem title='Dokumentumok' label='Ugrás a Dokumentumokhoz' path='/kozzetetelek/befektetesi-alapok-dokumentumai' />
            </SubFooter>

        </NBossCMSPage>
    );
}

function List(props) {
    const [category, setCategory] = useState([]);

    useEffect(() => {
        if (props.data) {
            const categories = getCategories(props.data);
            let _category = null;
            for (let i = 0; i < categories.length; i++) {
                if (!_category || (getValueFromParameterMap(categories[i], 'order') < getValueFromParameterMap(_category, 'order')))
                    _category = categories[i];
            }
            setCategory(getValueFromParameterMap(_category, 'key'));
        }
    }, [props.data]);

    const getOrderedList = (list) => {
        return list.sort((a, b) => (getValueFromParameterMap(a.type, 'order') > getValueFromParameterMap(b.type, 'order')) ? 1 : ((getValueFromParameterMap(b.type, 'order') > getValueFromParameterMap(a.type, 'order')) ? -1 : 0));
    };

    return (
        <div className='w-full flex flex-col items-center page-title-content-offset'>

            <div className='w-full hidden md:flex flex-col items-center'>

                <div className='w-full flex flex-col items-center'>

                    {getCategories(props.data).length > 1 && <div className='w-full px-site flex flex-col items-center'><div className='w-full max-w-boxed flex px-content'>{getCategories(props.data).map((item) => <CategoryButton key={item.id} data={item} active={category.indexOf(getValueFromParameterMap(item, 'key')) > -1} onClick={() => setCategory([getValueFromParameterMap(item, 'key')])} />)}</div></div>}

                    <div className='w-full flex flex-col items-center'>{getOrderedList(getTypeListByCategory(props.data, category)).map((item, index) => (<Type key={index} data={item} />))}</div>

                </div>

            </div>

            <div className='w-full flex md:hidden flex-col pb-16'>

                {getCategories(props.data).length > 0 && getCategories(props.data).map((item) => <CategoryContainer key={item.id} data={item} list={getOrderedList(getTypeListByCategory(props.data, getValueFromParameterMap(item, 'key')))} active={category.indexOf(getValueFromParameterMap(item, 'key')) > -1} onClick={() => setCategory([getValueFromParameterMap(item, 'key')])} />)}

            </div>

        </div>
    );
}

function CategoryButton(props) {
    const _data = {
        label: getValueFromParameterMap(props.data, 'label'),
        labelPlural: getValueFromParameterMap(props.data, 'label-plural'),
        icon: getValueFromParameterMap(props.data, 'icon') && getValueFromParameterMap(props.data, 'icon').downloadUrl,
        iconHover: getValueFromParameterMap(props.data, 'icon-hover') && getValueFromParameterMap(props.data, 'icon-hover').downloadUrl,
        order: getValueFromParameterMap(props.data, 'order'),
    }

    return (
        <button className={'relative flex-1 flex items-center justify-center gap-4 py-8 group ' + (props.active ? 'text-accent' : 'hover:text-accent')} onClick={props.onClick} style={{ order: _data.order || 1000 }}>

            {_data.icon && <div className='relative'>
                <img className='w-7' alt={_data.labelPlural} src={_data.icon} />
                {_data.iconHover && <img alt={_data.labelPlural} className={'absolute left-0 top-0 w-7 transition ' + (props.active ? 'opacity-100' : 'opacity-0 group-hover:opacity-100')} src={_data.iconHover} />}
            </div>}

            <div className='font-display text-xl transition'>{_data.labelPlural}</div>

            <div className='absolute bottom-0 w-full bg-primary-dark h-px flex items-center justify-center'>
                <div className={'h-[3px] bg-accent transition-all ' + (props.active ? 'w-full opacity-100' : 'w-0 opacity-0')} />
            </div>

        </button>
    );
}

function CategoryContainer(props) {
    const _data = {
        label: getValueFromParameterMap(props.data, 'label'),
        labelPlural: getValueFromParameterMap(props.data, 'label-plural'),
        icon: getValueFromParameterMap(props.data, 'icon') && getValueFromParameterMap(props.data, 'icon').downloadUrl,
        iconHover: getValueFromParameterMap(props.data, 'icon-hover') && getValueFromParameterMap(props.data, 'icon-hover').downloadUrl,
        order: getValueFromParameterMap(props.data, 'order'),
    }

    const getOrderedList = (list) => {
        return list.sort((a, b) => (getValueFromParameterMap(a.type, 'order') > getValueFromParameterMap(b.type, 'order')) ? 1 : ((getValueFromParameterMap(b.type, 'order') > getValueFromParameterMap(a.type, 'order')) ? -1 : 0));
    };

    return (
        <div className='w-full text-left flex flex-col' /*style={{ order: _data.order || 1000 }}*/>

            <button className={'w-full flex items-center text-left py-8 px-site ' + (props.active ? 'text-accent' : 'hover:text-accent')} onClick={props.onClick}>

                <div className='flex-1 text-2xl font-display'>{_data.labelPlural}</div>

                <div>
                    <TfiPlus />
                </div>

            </button>

            {props.active && <div className='flex flex-col'>

                {props.list && getOrderedList(props.list).map((item, index) => (<Type key={index} data={item} />))}

            </div>}

        </div>
    );
}

function Type(props) {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    const _data = {
        label: getValueFromParameterMap(props.data.type, 'label'),
        labelPlural: getValueFromParameterMap(props.data.type, 'label-plural'),
        content: getValueFromParameterMap(props.data.type, 'content'),
        order: getValueFromParameterMap(props.data.type, 'order'),
    }

    useEffect(() => {
        if (open && ref.current)
            window.scrollTo({ top: ref.current.getBoundingClientRect().top + window.scrollY, behavior: "smooth" });
    }, [open]);

    const getOrderedList = (list) => {
        return list.sort((a, b) => (getValueFromParameterMap(a, 'order') > getValueFromParameterMap(b, 'order')) ? 1 : ((getValueFromParameterMap(b, 'order') > getValueFromParameterMap(a, 'order')) ? -1 : 0));
    };

    return (
        <div ref={ref} className={'relative w-full flex flex-col py-8 max-md:first:pt-0 group ' + (open ? 'md:pt-20 pb-0' : 'md:py-20')} /*style={{ order: _data.order || 1000 }}*/>

            <div className='w-full flex flex-col items-center px-site'>

                <div className='w-full max-w-boxed px-content'>

                    <div className={'w-full flex flex-col md:flex-row gap-6 md:gap-0 ' + typeMobileInsetClassName}>

                        <div className='w-full md:w-[40%] text-xl xl:text-2xl font-display text-accent md:text-white pr-8'>{_data.labelPlural}</div>

                        <div className='flex-1 flex flex-col md:items-start gap-10'>
                            <div className='flex-1 text-xs md:text-sm' dangerouslySetInnerHTML={{ __html: _data.content }} />
                            <button className='rounded-button-accent' onClick={() => setOpen(!open)}>{open ? 'Bezárás' : 'Részletek'}</button>
                        </div>

                    </div>

                </div>

            </div>

            {(props.data.list && open) && <div className='w-full flex-1 flex flex-col items-start gap-0'>{getOrderedList(props.data.list).map((item, index) => (<Fund key={index} data={item} />))}</div>}

            <div className='absolute bottom-0 h-px w-full flex flex-col items-center px-site group-last:hidden'><div className='w-full max-w-boxed h-full px-content'><div className={'w-full h-full ' + typeMobileInsetClassName}><div className='w-full h-full bg-black/10' /></div></div></div>

        </div>
    );
}

function Fund(props) {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    const _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        groupName: getValueFromParameterMap(props.data, 'group-name'),
        description: getValueFromParameterMap(props.data, 'description'),
        page: getValueFromParameterMap(props.data, 'page'),
        order: getValueFromParameterMap(props.data, 'order'),
    }

    useEffect(() => {
        if (open && ref.current)
            window.scrollTo({ top: ref.current.getBoundingClientRect().top + window.scrollY, behavior: "smooth" });
    }, [open]);

    return (
        <div ref={ref} className={'w-full flex flex-col items-center transition-all duration-500 group/item ' + (open ? 'bg-primary-dark mt-10' : '')} /*style={{ order: _data.order || 1000 }}*/>

            <div className='w-full px-site flex flex-col items-center'>

                <div className='w-full flex max-w-boxed px-content'>

                    <div className='w-0 md:w-[40%]' />

                    <button className={'relative flex-1 text-left text-accent hover:text-white flex flex-row gap-8 items-center py-10 transition group/button ' + typeMobileInsetClassName} onClick={() => setOpen(!open)}>

                        <div className='flex-1 text-sm md:text-xl font-medium'>{_data.groupName ? _data.groupName : _data.name}</div>

                        {open ? <TfiMinus className='group-hover/button:rotate-180 transition' /> : <TfiPlus className='group-hover/button:rotate-90 transition' />}

                        {!open && <div className={'absolute bottom-0 left-0 right-0 w-full h-px group-last/item:hidden ' + typeMobileInsetClassName}>
                            <div className='w-full h-full bg-black/10' />
                        </div>}

                    </button>

                </div>

            </div>

            {open && <div className='w-full flex flex-col'>

                <div className='w-full px-site flex flex-col items-center md:pt-8 pb-12 md:pb-32'>

                    <div className='w-full flex flex-col md:flex-row max-w-boxed px-content'>

                        <div className='hidden md:flex w-[40%] pr-8'>

                            <h1 className='flex-1 text-xl sm:text-3xl 2xl:text-4xl font-display'>{_data.groupName ? _data.groupName : _data.name}</h1>

                        </div>

                        <div className={'flex-1 flex flex-col md:items-start gap-12 ' + typeMobileInsetClassName} onClick={() => setOpen(!open)}>

                            <div className='w-full text-xs xl:text-sm leading-relaxed content-text' dangerouslySetInnerHTML={{ __html: _data.description }} />

                            <Link className='rounded-button-accent-reverse' to={_data.page}>Tovább a részletes adatokra</Link>

                        </div>

                    </div>

                </div>

                <DataGrid data={props.data} excerpt />

            </div>}

        </div>
    );
}