import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/hu';

import PageTitle from 'elements/PageTitle';
import Modal from 'elements/Modal';
import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function JobListPage(props) {
    const [page, setPage] = useState(null);
    const [id, setId] = useState(null);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('id')) setId(parseInt(searchParams.get('id')))
        else setId(null);
    }, [searchParams]);

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        content: getValueFromParameterMap(page, 'content'),
        jobList: getValueFromParameterMap(page, 'job-list') && getValueFromParameterMap(page, 'job-list').contentList,
    }

    const getJobById = (id) => {
        if (id && _data.jobList) for (let i = 0; i < _data.jobList.length; i++) if (_data.jobList[i].id === id) return _data.jobList[i];
        return null
    };

    const getOrderedList = (list) => {
        let _list = list.sort((a, b) => (getValueFromParameterMap(a, 'date') > getValueFromParameterMap(b, 'date')) ? 1 : ((getValueFromParameterMap(b, 'date') > getValueFromParameterMap(a, 'date')) ? -1 : 0));
        _list.reverse();
        return _list;
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col items-center text-white'>

            <PageTitle className='' titleClassName='xl:w-min' title={_data.title} highlightSubTitle={_data.subTitle} content={_data.content} />

            <div className='w-full flex flex-col items-center px-site pb-site'>

                {(_data.jobList && _data.jobList.length > 0) && <div className='w-full max-w-boxed gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                    {getOrderedList(_data.jobList).map((item) => <JobItem key={item.id} data={item} />)}
                </div>}

                {(!_data.jobList || _data.jobList.length === 0) && <div className='w-full max-w-boxed gap-8 flex px-site'>
                    <div className='w-full bg-white/5 text-center p-8 flex flex-col gap-2'>
                        <div className='font-displayy text-xl font-semibold'>Jelenleg nincs nyitott pozíciónk.</div>
                        <div className='text-sm'>Kérjük, látogasson vissza később!</div>
                    </div>
                </div>}

            </div>

            {id && <JobModal data={getJobById(id)} onClose={() => setSearchParams({})} />}

        </NBossCMSPage>
    );
}

export function JobItem(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        excerpt: getValueFromParameterMap(props.data, 'excerpt'),
        date: getValueFromParameterMap(props.data, 'date'),
        email: getValueFromParameterMap(props.data, 'contact-email'),
    }

    const scrollBorderClassName = 'border-y border-r last:border-r-0 md:border-x-0 md:border-t-0 md:border-b-2 border-white/10 md:border-accent';
    const borderClassName = 'border-b-2 border-accent md:border-white/10 md:hover:border-accent'

    return (
        <div className={'relative flex flex-shrink-0 flex-col items-center justify-between gap-10 p-10 text-white transition ' + (props.scroll ? ('w-[70vw] md:w-full md:bg-white/5 ' + scrollBorderClassName) : ('w-full bg-white/5 md:bg-transparent hover:md:bg-white/5 ' + borderClassName))}>

            <div className='w-full flex flex-col gap-4 items-center'>
                <div className='w-full text-xs font-semibold'><Moment format='YYYY. MMMM DD.' withTitle>{new Date(_data.date)}</Moment></div>
                <div className='w-full text-lg font-medium'>{_data.title}</div>
                <div className='w-full text-xs md:text-sm leading-relaxed md:leading-relaxed'>{_data.excerpt}</div>
            </div>

            <Link to={'/karrier?id=' + props.data.id} className='rounded-button-accent-reverse'>Tovább</Link>


        </div>
    );
}

function JobModal(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        excerpt: getValueFromParameterMap(props.data, 'excerpt'),
        date: getValueFromParameterMap(props.data, 'date'),
        content: getValueFromParameterMap(props.data, 'content'),
        email: getValueFromParameterMap(props.data, 'contact-email'),
    }

    return (
        <Modal className='flex flex-col gap-4 flex-1 overflow-y-auto' onClose={props.onClose}>

            <div className='flex flex-col gap-1'>
                <div className='w-full text-xs font-semibold'><Moment format='YYYY. MMMM DD.' withTitle>{new Date(_data.date)}</Moment></div>
                <div className='font-display text-3xl'>{_data.title}</div>
            </div>

            <div className='text-sm'>{_data.excerpt}</div>
            <div className='text-xs' dangerouslySetInnerHTML={{ __html: _data.content }} />

            <a href={'mailto:' + _data.email + '?subject=' + _data.title + ' - Érdkődés a pozíció iránt'} className='rounded-button-accent mt-4'>Jelentkezem a pozícióra</a>

        </Modal>
    );
}