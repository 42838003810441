import axios from 'axios';
import { useState, useEffect } from 'react';
import { convert } from 'html-to-text';
import Moment from 'react-moment';
import 'moment/locale/hu';
import { FiArrowDown } from 'react-icons/fi';

import PageTitle, { PageTitleSubMenuList } from 'elements/PageTitle';
import { OpenableGroup } from 'pages/DocumentDownloadPage';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { getByParam } from 'utils/GenericUtils';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function PublicationListPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        year: getValueFromParameterMap(page, 'year'),
        previous: getValueFromParameterMap(page, 'previous'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col flex-1'>

            <PageTitle className={_data.year ? 'py-page-title-large-offset' : 'py-page-title-small-offset'} title={_data.title} type='title-only' bottomChildren={<PageTitleSubMenuList includeParent />} />

            {_data.previous && <PublicationList previous={_data.previous} />}
            {_data.year && <PublicationList year={_data.year} />}

        </NBossCMSPage>
    );
}

function PublicationList(props) {
    const [publicationList, setPublicationList] = useState(null);
    const [categorizedPublicationList, setCategorizedPublicationList] = useState(null);

    useEffect(() => {
        if (props.year) {
            setCategorizedPublicationList(null);
            const url = 'https://www.diofaalapkezelo.hu/backings/publicationControl/getOrderedPublications'
            const body = { 'fetchSize': 0, 'firstResult': 0, 'year': props.year }
            axios.post(url, body).then(function (response) { if (response.data) setPublicationList(response.data.result); }).catch(function (error) { console.log(error); });
        } else if (props.previous) {
            setPublicationList(null);
            const url = 'https://www.diofaalapkezelo.hu/backings/publicationControl/findAll'
            const body = { 'firstResult': 0, 'fetchSize': 0 }
            axios.post(url, body).then(function (response) { if (response.data) setCategorizedPublicationList(categorize(response.data.result)); }).catch(function (error) { console.log(error); });
        }
    }, [props.year, props.previous]);

    const categorize = (data) => {
        let list = [];
        for (let i = 0; i < data.length; i++) {
            const year = new Date(data[i].date).getFullYear();
            if (year !== new Date().getFullYear() && year !== 1970) {
                let obj = getByParam(list, 'year', year);
                if (!obj) {
                    obj = { 'year': year, 'list': [] };
                    list.push(obj);
                }
                obj.list.push(data[i]);
            }
        }
        list.reverse();
        return list;
    };

    return (
        <div className='flex-1 w-full bg-neutral-50 flex flex-col items-center px-site-mobile md:pb-16 text-primary'>

            <div className={'w-full max-w-boxed ' + (props.year ? 'page-title-content-offset-large' : 'page-title-content-offset')}>

                {publicationList && <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 floating-card-grid-gap-small'>{publicationList.map((item) => (<PublicationListItem key={item.id} data={item} />))}</div>}
                {categorizedPublicationList && <div className='w-full flex flex-col floating-card-grid-gap-small'>{categorizedPublicationList.map((item) => (<PublicationListCategoryItem key={item.year} data={item} />))}</div>}

            </div>

        </div>
    );
}

function PublicationListCategoryItem(props) {
    const [list, setList] = useState(null);


    useEffect(() => {
        if (props.data) setList(props.data.list.reverse());
    }, [props.data]);

    return (
        <OpenableGroup title={props.data.year + '. évi határozatok és közzétételek'} openable>

            <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 floating-card-grid-gap-small'>
                {list && list.map((item) => (<PublicationListItem key={item.id} data={item} />))}
            </div>

        </OpenableGroup>
    );
}

export function PublicationListItem(props) {
    return (
        <div className={'floating-card floating-card-padding flex flex-col items-center justify-between gap-6 ' + (props.className || '')}>

            <div className='w-full flex flex-col gap-3'>
                <div className='text-xs font-semibold'><Moment format='YYYY. MMMM DD.' withTitle>{new Date(props.data.date)}</Moment></div>
                <div className='text-lg font-medium leading-tight'>{props.data.title}</div>
                <div className='text-sm'>{convert(props.data.content, { selectors: [{ selector: 'a', options: { ignoreHref: true } },] })}</div>
            </div>

            <a className='rounded-button-primary rounded-button-with-icon group' href={props.data.url.replace('http://', 'https://')}>
                <div className='rounded-button-icon-primary'><FiArrowDown /></div>
                Dokumentum letöltése
            </a>

        </div>
    );
}