import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FiArrowRight } from 'react-icons/fi';

import PageTitle from 'elements/PageTitle';
import { PublicationListItem } from 'pages/PublicationListPage';

export default function LatestPublications(props) {
    const [publications, setPublications] = useState(null);

    const path = '/kozzetetelek';

    useEffect(() => {
        const url = 'https://www.diofaalapkezelo.hu/backings/publicationControl/getOrderedPublications'
        const body = { 'fetchSize': 0, 'firstResult': 0, 'year': new Date().getFullYear() }
        axios.post(url, body).then(function (response) { if (response.data) setPublications(response.data.result); }).catch(function (error) { console.log(error); });
    }, []);

    return (
        <div className='w-full flex flex-col items-center bg-neutral-50'>

            <PageTitle colorClassName='bg-neutral-50 text-primary' paddingClassName='pt-12 pb-12 md:pt-24 md:pb-16' innerClassName='md:flex-row gap-4' title='Közzétételek' disableContentPadding contentChildren={<div className='w-full h-full flex items-center md:justify-end'>
                <Link to={path} className='hidden md:flex rounded-button-primary'>Összes közzététel</Link>
                <Link to={path} className='md:hidden icon-button-generic'>Összes közzététel<FiArrowRight /></Link>
            </div>} />

            <div className='w-full relative flex'>

                <div className='absolute w-full h-[75%] md:h-[50%] left-0 bottom-0 bg-premium-light' />

                <div className='w-full flex flex-col items-center px-site-mobile z-10'>
                    <div className='w-full md:max-w-boxed flex gap-6 md:gap-8 md:grid md:grid-cols-3 overflow-x-scroll md:overflow-x-visible hidden-scrollbar'>
                        {publications && publications.map((item, index) => (index < 3 && <PublicationListItem className='w-[70vw] md:w-full flex-shrink-0' key={item.id} data={item} />))}
                    </div>
                </div>

            </div>

        </div>
    );
}