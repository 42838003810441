import { useState, useEffect } from 'react';
import { motion, useAnimationControls } from 'framer-motion'

import usePrevious from 'hooks/usePrevious';

const defaultDuration = 0.6;

export default function TextChanger(props) {
    const [text, setText] = useState(props.text);
    const prevText = usePrevious(text);

    const controls = useAnimationControls();

    useEffect(() => {
        if (prevText) {
            controls.set('start');
            controls.start('end');
        }
    }, [text])

    useEffect(() => { setText(props.text); }, [props.text]);

    const variantMap = {
        'up': {
            'a': { start: { opacity: 1, y: 0 }, end: { opacity: 0, y: '-100%' } },
            'b': { end: { opacity: 1, y: 0 }, start: { opacity: 0, y: '100%' } }
        },
        'down': {
            'a': { start: { opacity: 1, y: 0 }, end: { opacity: 0, y: '100%' } },
            'b': { end: { opacity: 1, y: 0 }, start: { opacity: 0, y: '-100%' } }
        },
    }

    return (
        <div className={'relative ' + (props.className || '')}>

            <div className='pointer-events-none opacity-0'>{text}</div>

            <motion.div
                className='w-full absolute top-0 left-0 pointer-events-none'
                animate={controls}
                style={{ originX: 0 }}
                variants={{ start: { opacity: 1, y: 0 }, end: { opacity: 0, y: (props.direction && props.direction === 'down') ? '100%' : '-100%' } }}
                transition={{ duration: props.duration || defaultDuration }}>

                {prevText}

            </motion.div>

            <motion.div
                className='w-full absolute top-0 left-0 pointer-events-none'
                animate={controls}
                style={{ originX: 0 }}
                variants={{ end: { opacity: 1, y: 0 }, start: { opacity: 0, y: (props.direction && props.direction === 'down') ? '-100%' : '100%' } }}
                transition={{ duration: props.duration || defaultDuration }}>

                {text}

            </motion.div>

        </div >
    );
}