import { useState, useEffect } from 'react';

import PageTitle from 'elements/PageTitle';
import { SubFooter, SubFooterButtonItem } from 'components/Footer';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';


export default function ManagementPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        list: getValueFromParameterMap(page, 'person-list') && getValueFromParameterMap(getValueFromParameterMap(page, 'person-list'), 'management-person-list') && getValueFromParameterMap(getValueFromParameterMap(page, 'person-list'), 'management-person-list').contentList,
    }

    useEffect(() => { setHeaderDark(); }, []);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col'>

            <PageTitle className='py-page-title-small-offset' title={_data.title} subTitle={_data.subTitle} type='title-only' /*bottomChildren={<PageTitleSubMenuList includeParent />}*/ />

            <div className='flex-1 w-full bg-neutral-50 flex flex-col items-center px-site-mobile md:pb-16 text-primary'>

                <div className={'w-full max-w-boxed px-content page-title-content-offset'}>

                    <div className='flex flex-col sm:gap-8 xl:gap-16 divide-y-4 sm:divide-y-0 divide-accent'>
                        {_data.list && _data.list.map((item) => (getValueFromParameterMap(item, 'type') === 'MANAGEMENT' && <Person key={item.id} data={item} />))}
                    </div>

                </div>

            </div>

            <PageTitle className='flex-1 border-b-4 border-accent' title='Felügyelő Bizottság' paddingClassName='py-page-title-row' type='title-only' contentChildren={<div className='grid grid-cols-1 md:grid-cols-3 gap-4 2xl:gap-8 pt-8'>
                {_data.list && _data.list.map((item) => (getValueFromParameterMap(item, 'type') !== 'MANAGEMENT' && <Name key={item.id} data={item} />))}
            </div>} />

            <SubFooter>
                <SubFooterButtonItem title='A Gránit Alapkezelőről' label='Ugrás a bemutatkozáshoz' path='/rolunk' />
                <SubFooterButtonItem title='Adataink' label='Ugrás az alapkezelő adataihoz' path='/kozzetetelek/befektetesi-alapok-dokumentumai' />
            </SubFooter>

        </NBossCMSPage>
    );
}

function Person(props) {
    const _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        introduction: getValueFromParameterMap(props.data, 'introduction'),
        position: getValueFromParameterMap(props.data, 'position'),
        type: getValueFromParameterMap(props.data, 'type'),
    }

    return (
        <div className='w-full bg-white shadow-3xl flex flex-col sm:flex-row overflow-hidden'>

            <div className='w-full sm:w-[40%] xl:w-[30%] aspect-[175/260] bg-cover bg-top bg-no-repeat' style={{ backgroundImage: 'url(' + _data.image + ')' }} />

            <div className='flex-1 flex flex-col -mt-8 sm:mt-0'>

                <div className='flex flex-col gap-2 bg-primary-dark p-8 mx-4 sm:-ml-8 sm:mr-0 shadow-3xl border-t-4 border-accent'>
                    <div className='text-4xl 2xl:text-5xl font-display text-white'>{_data.name}</div>
                    <div className='font-medium text-white text-lg'>{_data.position}</div>
                </div>

                <div className='flex-1 text-xs xl:text-sm paragraph-gap p-8 leading-relaxed' dangerouslySetInnerHTML={{ __html: _data.introduction }} />
            </div>

        </div>
    );
}

function Name(props) {
    const _data = {
        name: getValueFromParameterMap(props.data, 'name'),
    }

    return (
        <div className='p-8 bg-white/5 font-display text-xl 2xl:text-3xl border-b-4 border-white/10 flex items-center justify-center'>
            {_data.name}
        </div>
    );
}