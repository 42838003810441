import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/hu';

import PageTitle, { PageTitleMenuList, PageTitleMenuListItem } from 'elements/PageTitle';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { getSubFolders, getFolderFiles } from 'utils/LegacyUtils';
import { getByParam } from 'utils/GenericUtils';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function DocumentDownloadPage(props) {
    const [page, setPage] = useState(null);
    const [categories, setCategories] = useState([]);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        newsList: getValueFromParameterMap(page, 'news-list') && getValueFromParameterMap(page, 'news-list').contentList,
    }

    const getAllCategories = (list) => {
        let _list = [];
        if (list) {
            for (let i = 0; i < list.length; i++) {
                const categoryList = getValueFromParameterMap(list[i], 'category');
                if (categoryList) {
                    for (let j = 0; j < categoryList.length; j++) {
                        if (!getByParam(_list, 'key', getValueFromParameterMap(categoryList[j], 'key')))
                            _list.push({ label: getValueFromParameterMap(categoryList[j], 'label'), key: getValueFromParameterMap(categoryList[j], 'key'), order: getValueFromParameterMap(categoryList[j], 'order') });
                    }
                }
            }
        }
        return _list;
    };

    const handleCategoryClick = (value) => {
        let list = [...categories];
        if (list.indexOf(value) > -1) list.splice(list.indexOf(value), 1);
        else list.push(value);
        setCategories(list);
    };

    const isIsCategory = (categories, item) => {
        const categoryList = getValueFromParameterMap(item, 'category');
        if (categoryList) {
            for (let i = 0; i < categoryList.length; i++) {
                if (categories.indexOf(getValueFromParameterMap(categoryList[i], 'key')) > -1)
                    return true;
            }
        }
        return false;
    };

    const getOrderedList = (list) => {
        let _list = list.sort((a, b) => (getValueFromParameterMap(a, 'date') > getValueFromParameterMap(b, 'date')) ? 1 : ((getValueFromParameterMap(b, 'date') > getValueFromParameterMap(a, 'date')) ? -1 : 0));
        _list.reverse();
        return _list;
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='flex-1 w-full flex flex-col'>

            <PageTitle className='py-page-title-large-offset' title={_data.title} type='title-only' bottomChildren={<CategoryFilter categories={categories} onClick={(value) => handleCategoryClick(value)} data={getAllCategories(_data.newsList)} />} />

            <div className='flex-1 w-full flex flex-col items-center px-site pb-site bg-neutral-50'>
                <div className='w-full max-w-boxed gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 page-title-content-offset-large'>
                    {_data.newsList && getOrderedList(_data.newsList).map((item) => ((categories.length === 0 || isIsCategory(categories, item)) && <NewsItem scroll className='w-[70vw] md:w-full flex-shrink-0' key={item.id} data={item} />))}
                </div>
            </div>

        </NBossCMSPage>
    );
}

function NewsItem(props) {
    const _data = {
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        title: getValueFromParameterMap(props.data, 'title'),
        excerpt: getValueFromParameterMap(props.data, 'excerpt'),
        date: getValueFromParameterMap(props.data, 'date'),
        category: getValueFromParameterMap(props.data, 'category'),
    }

    return (

        <div className='w-full h-full bg-white md:shadow-2xl p-8 text-primary border-b-2 border-accent'>

            <NewsItemContent data={props.data} />

        </div>
    );
}

function CategoryFilter(props) {
    return (
        <PageTitleMenuList>
            {props.data && props.data.map((item) => <button key={item.key} style={{ order: item.order }} onClick={() => props.onClick(item.key)}><PageTitleMenuListItem label={item.label} active={props.categories.indexOf(item.key) > -1} /></button>)}
        </PageTitleMenuList>
    );
}

export function NewsItemContent(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        excerpt: getValueFromParameterMap(props.data, 'excerpt'),
        date: getValueFromParameterMap(props.data, 'date'),
    }

    return (
        <div className='w-full h-full flex flex-col gap-10 items-start justify-between'>

            <div className='w-full flex flex-col gap-6'>
                <div className='text-xs font-semibold'><Moment format='YYYY. MMMM DD.' withTitle>{new Date(_data.date)}</Moment></div>
                <div className='md:text-sm lg:text-lg leading-tight md:leading-tight lg:leading-tight font-medium'>{_data.title}</div>
                <div className='text-xs lg:text-sm line-clamp-4'>{_data.excerpt}</div>
            </div>

            <Link to={'/hirek/' + props.data.key} className={((props.type && props.type === 'PREMIUM') ? 'rounded-button-premium' : 'rounded-button-accent-reverse')}>Elolvasom</Link>

        </div>
    );
}