import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import 'moment/locale/hu';

export default function DataField(props) {
    const getClassName = () => {
        let className = '';
        if (props.config) {
            // console.log(props.config);
            if (props.config.strong) className += ' font-semibold';
            if (props.config.center) className += ' text-center';
        }
        return className;
    };

    return (
        <div className={' ' + getClassName() + ' ' + (props.className || '')}>
            {props.value ? <>
                {(props.config && props.config.type && props.config.type === 'currency') && <NumericFormat displayType='text' value={props.value} allowLeadingZeros thousandSeparator=' ' suffix={props.config.currency ? (' ' + props.config.currency) : ' Ft'} />}
                {(props.config && props.config.type && props.config.type === 'date') && <Moment format='YYYY. MMMM DD.' withTitle>{new Date(props.value)}</Moment>}
                {(!props.config || !props.config.type) && <span dangerouslySetInnerHTML={{ __html: props.value }} />}
            </> : ((props.config && props.config.displayLoading) ? 'Adatok betöltése ...' : '-')}
        </div>
    );
}