import { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from 'recharts';
import Moment from 'react-moment';
import 'moment/locale/hu';

export default function Graph(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data)
            setData(formatData(props.data));
    }, [props.data]);

    const formatData = (data) => {
        let maxRate = 0;
        if (data) {
            let _data = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].rate > maxRate) maxRate = data[i].rate;
                let _date = new Date(data[i].date);
                data[i].time = _date.getTime();
                data[i].rate = parseFloat(data[i].rate);
                _data.push(data[i]);
            }
            return _data;
        }
        return [];
    };

    const getMinData = () => {
        let min = getMaxData() || 0;
        if (props.data) for (let i = 0; i < props.data.length; i++) if (props.data[i].rate < min) min = props.data[i].rate;
        return min;
    };

    const getMaxData = () => {
        let max = 0;
        if (props.data) for (let i = 0; i < props.data.length; i++) if (props.data[i].rate > max) max = props.data[i].rate;
        return max;
    };

    const getYears = (data) => {
        if (data) {
            let yearList = [];
            for (let i = 0; i < data.length; i++) {
                let y = new Date(data[i].date).getFullYear();
                if (yearList.indexOf(y) === -1) {
                    yearList.push(y);
                }
            }
            return yearList;
        }
        return [];
    };

    const formatXAxis = (tickFormat) => { return new Date(tickFormat).getFullYear(); };

    // const formatYAxis = (tickFormat) => {
    //     if (tickFormat < 1)
    //         return Math.floor(tickFormat * 10) / 10;
    //     else
    //         return (tickFormat * 2 / 2).toFixed(1);
    // };

    const getTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='flex flex-col gap-0.5 bg-secondary p-2 text-white text-xs shadow-lg'>
                    <div className=''><Moment format='YYYY. MMMM DD.' withTitle>{new Date(label)}</Moment></div>
                    <div className='text-accent'><span className='font-semibold'>Árfolyam:</span> {payload[0].value + (props.currency ? (' ' + props.currency) : '')}</div>
                </div>
            );
        }
        return null;
    };

    const getXTicks = (data) => {
        if (data) {
            const years = getYears(data);
            let yearTimes = [];

            if (years.length > 1) {
                years.shift();
                for (let i = 0; i < years.length; i++) yearTimes.push(new Date(years[i], 0, 1).getTime());
            } else {
                yearTimes[0] = new Date(data[0].date).getTime();
                yearTimes[1] = new Date(data[data.length - 1].date).getTime();
            }

            // console.log('X ticks between [' + years[0] + '] and [' + years[years.length - 1] + ']')
            // console.log(yearTimes);

            return yearTimes;
        }
    };

    const getYTicks = (data) => {
        let minData = Math.floor(getYDomains(getMinData(), null) * 10) / 10;
        let maxData = getYDomains(null, getMaxData());

        const numbers = [];
        for (let i = minData; i <= maxData; i = roundedToFixed((i + 0.1), 1))
            numbers.push(i);

        // console.log('Y ticks between [' + minData + '] and [' + maxData + ']');
        // console.log(numbers);

        return numbers;
    };

    const roundedToFixed = (input, digits) => {
        var rounder = Math.pow(10, digits);
        return parseFloat((Math.round(input * rounder) / rounder).toFixed(digits));
    }

    const getYDomains = (dataMin, dataMax) => {
        if (dataMin) {
            const min = Math.min(dataMin, 1);
            return min;
        } else if (dataMax) {
            const max = Math.ceil(dataMax * 10) / 10;
            return max;
        }
    };

    if (data.length > 0)
        return (
            <div className='w-full h-full'>

                <ResponsiveContainer width='100%' height='100%'>

                    <LineChart data={data} margin={{ top: 5, left: -15, right: 0, bottom: 0 }}>

                        <CartesianGrid strokeDasharray='3 3' />

                        <XAxis
                            dataKey='time'
                            tickMargin={10}
                            type='number'
                            // tickCount={getYears(data).length + 3}
                            domain={['dataMin', 'dataMax']}
                            ticks={getXTicks(data)}
                            interval={0}
                            tickFormatter={(tick) => formatXAxis(tick)}
                        />

                        <YAxis
                            dataKey='rate'
                            tickMargin={10}
                            type='number'
                            // tick={<CustomTick />}
                            ticks={getYTicks(data)}
                            interval={0}
                            domain={[dataMin => (getYDomains(dataMin, null)), dataMax => (getYDomains(null, dataMax))]}
                        />

                        <Tooltip content={getTooltip} cursor={{ fill: 'transparent' }} />

                        <Line type='monotone' dataKey='rate' stroke='#015359' dot={false} strokeWidth={2} />

                    </LineChart  >

                </ResponsiveContainer>

            </div>
        );
    return null;
}

// function CustomTick(props) {
//     const { payload: { value } } = props;
//     // const isWithIcon = NAME_WITH_ICON.includes(value);

//     return (
//         <Text {...props} fill={"green"} width={10}>
//             {/* {isWithIcon && <GoGlobe />} */}
//             {value}
//         </Text>
//     );
// }