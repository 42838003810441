import axios from 'axios';
import { useState, useEffect } from 'react';
import { FaRegFolderOpen } from 'react-icons/fa6';
import { BiSubdirectoryRight } from 'react-icons/bi';

import { getSubFolders, getFolderFiles } from 'utils/LegacyUtils';

// export const getSubFolders = (id, cb) => {
//     if (id) {
//         const url = 'https://www.diofaalapkezelo.hu/backings/diofaFileRepositoryFolderControl/getSubFolders'
//         const body = { 'folderId': id }

//         axios.post(url, body).then(function (response) {
//             if (response.data && response.data.result) cb(response.data.result);
//         }).catch(function (error) {
//             console.log(error);
//         });
//     }
// };

// export const getFolderFiles = (id, cb) => {
//     if (id) {
//         const url = 'https://www.diofaalapkezelo.hu/backings/diofaFileRepository/getFolderFiles'
//         const body = { 'parentFolderId': id }

//         axios.post(url, body).then(function (response) {
//             if (response.data && response.data.result) cb(response.data.result);
//         }).catch(function (error) {
//             console.log(error);
//         });
//     }
// };

const selectClassName = 'light-select z-10';

export default function LegacyDownload(props) {
    const [folderList, setFolderList] = useState([]);

    useEffect(() => {
        if (props.folderId) getSubFolders(props.folderId, (value) => setFolderList(value));
    }, [props.folderId]);

    return (
        <div className={'w-full ' + (props.className || '')}>

            {folderList && <FolderSelect data={folderList} />}

        </div>
    );
}

function FolderSelect(props) {
    const [selected, setSelected] = useState('');

    const [fileList, setFileList] = useState([]);
    const [folderList, setFolderList] = useState([]);

    useEffect(() => {
        if (selected) getSubFolders(selected, (value) => {
            if (value.length > 0)
                setFolderList(value);
            else
                getFolderFiles(selected, (value) => { setFileList(value) })
        });
    }, [selected]);

    // useEffect(() => {
    //     console.log(folderList);
    // }, [folderList]);

    return (
        <div className='w-full flex flex-col overflow-hidden'>

            <div className='w-full flex h-12'>

                <div className='aspect-square h-full bg-primary flex items-center justify-center text-white text-lg'>
                    <FaRegFolderOpen />
                </div>

                <select className={'flex-1 h-full shadow-3xl ' + selectClassName} value={selected} onChange={(e) => setSelected(e.target.value)}>
                    <option value='' disabled selected hidden>Kérjük, válasszon</option>
                    {props.data && props.data.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </select>

            </div>

            {(fileList && fileList.length > 0) && <DownloadSelect data={fileList} />}
            {(folderList && folderList.length > 0) && folderList.map((item, index) => <DownloadSelect key={item.id} label={item.name} id={item.id} />)}

        </div>
    );
}

function DownloadSelect(props) {
    const [list, setList] = useState([]);

    const [selected, setSelected] = useState('');

    useEffect(() => { if (props.data) setList(props.data); }, [props.data]);

    useEffect(() => { if (props.id) getFolderFiles(props.id, (value) => setList(value)); }, [props.id]);

    useEffect(() => { setSelected('') }, [list]);

    // useEffect(() => { console.log(selected); }, [selected]);

    const download = (id) => {
        const url = 'https://www.diofaalapkezelo.hu/backings/diofaFileRepository/getFileWithForceDownload?id=' + id;
        window.open(url, '_self');
    };

    return (
        <div className='w-full flex flex-col gap-2 overflow-hidden'>

            {props.label && <div className='px-2 text-sm font-bold'>{props.label}</div>}

            <div className='w-full flex h-12'>

                <div className='aspect-square h-full bg-neutral-100 flex items-center justify-center text-primary text-lg'>
                    <BiSubdirectoryRight />
                </div>

                <select className={'w-full ' + selectClassName} value={selected} onChange={(e) => setSelected(e.target.value)}>
                    <option value='' disabled selected hidden>Kérjük, válasszon</option>
                    {list && list.map((item, index) => <option key={item.id} value={item.id} className='generic-select-option'>{item.name}</option>)}
                </select>

                <button className={'h-full  flex items-center justify-center text-lg px-4 ' + (selected !== '' ? 'bg-primary text-white' : ' bg-neutral-100 text-neutral-200')} onClick={() => download(selected)}>
                    <div className='text-xs uppercase font-semibold'>Letöltés</div>
                    {/* <BiSubdirectoryRight /> */}
                </button>

            </div>

        </div>
    );
}