import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import Moment from 'react-moment';
import 'moment/locale/hu';

import PageTitle from 'elements/PageTitle';
import { JobItem } from 'pages/JobListPage';

import { getValueFromParameterMap } from 'cms/NBossCMS';

export default function LatestJobs(props) {
    const path = '/karrier';

    return (
        <div className='w-full flex flex-col items-center bg-secondary'>

            <PageTitle colorClassName='bg-secondary text-white' paddingClassName='pt-12 pb-12 md:pt-24 md:pb-16' innerClassName='md:flex-row gap-4' title='Állásajánlataink' disableContentPadding contentChildren={<div className='w-full h-full flex items-center md:justify-end'>
                <Link to={path} className='hidden md:flex rounded-button-white'>További állásajánlataink</Link>
                <Link to={path} className='md:hidden icon-button-generic'>További állásajánlataink<FiArrowRight /></Link>
            </div>} />

            <div className='w-full flex flex-col items-center px-site-mobile z-10'>
                <div className='w-full md:max-w-boxed flex gap-0 md:gap-8 md:grid md:grid-cols-3 overflow-x-scroll md:overflow-x-visible hidden-scrollbar'>
                    {props.data && props.data.map((item, index) => (index < 3 && <JobItem scroll key={item.id} data={item} />))}
                </div>
            </div>

        </div>
    );
}

// export function JobItem(props) {
//     const _data = {
//         image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
//         title: getValueFromParameterMap(props.data, 'title'),
//         excerpt: getValueFromParameterMap(props.data, 'excerpt'),
//         date: getValueFromParameterMap(props.data, 'date'),
//         category: getValueFromParameterMap(props.data, 'category'),
//     }

//     return (
//         <div className='relative w-[70vw] md:w-full flex flex-shrink-0 flex-col items-center justify-between gap-10 md:bg-white/5 p-10 text-white border-y border-r last:border-r-0 md:border-x-0 md:border-t-0 md:border-b-2 border-white/10 md:border-accent'>

//             <div className='w-full flex flex-col gap-4 items-center'>
//                 <div className='w-full text-xs font-semibold'><Moment format='YYYY. MMMM DD.' withTitle>{new Date(_data.date)}</Moment></div>
//                 <div className='w-full text-lg font-medium'>{_data.title}</div>
//                 <div className='w-full text-xs md:text-sm leading-relaxed md:leading-relaxed'>{_data.excerpt}</div>
//             </div>

//             <Link className='rounded-button-accent-reverse'>Tovább</Link>

//         </div>
//     );
// }