import { useState, createContext, useContext } from 'react';

export const WebsiteContext = createContext(null);
export const useWebsite = () => { return useContext(WebsiteContext); };

export default function WebsiteContextProvider(props) {
	const [lightHeader, setLightHeader] = useState(false);

	const setHeaderLight = () => { setLightHeader(true); };
	const setHeaderDark = () => { setLightHeader(false); };

	const value = {
		lightHeader,
		setHeaderLight,
		setHeaderDark,
	};

	return (
		<WebsiteContext.Provider value={value}>
			{props.children}
		</WebsiteContext.Provider>
	);
}
