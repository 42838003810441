import { useState, useEffect, useRef } from 'react';
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";

import PageTitle from 'elements/PageTitle';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function GlossaryPage(props) {
    const [term, setTerm] = useState('');
    const [letter, setLetter] = useState(null);
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    useEffect(() => {
        if (term !== '') setLetter(null);
    }, [term]);

    useEffect(() => {
        if (letter) setTerm('');
    }, [letter]);

    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        glossaryList: getValueFromParameterMap(page, 'glossary-list') && getValueFromParameterMap(getValueFromParameterMap(page, 'glossary-list'), 'glossary-list') && getValueFromParameterMap(getValueFromParameterMap(page, 'glossary-list'), 'glossary-list').contentList
    }

    const letterAvailable = (letter) => {
        if (_data.glossaryList)
            for (let i = 0; i < _data.glossaryList.length; i++)
                if (getValueFromParameterMap(_data.glossaryList[i], 'title')[0].toUpperCase() === letter.toUpperCase())
                    return true;
        return false;
    };

    const toggleLetter = (_letter) => {
        let to = _letter;
        if (letter && letter === _letter)
            to = null;
        setLetter(to);
    };

    const isActive = (_letter) => {
        return letter && letter === _letter.toLocaleUpperCase() ? true : false;
    };

    const getLatin = (letter) => {
        if (letter === 'Á') return 'A';
        if (letter === 'É') return 'E';
        if (letter === 'Í') return 'I';
        if (letter === 'Ó' || letter === 'Ö' || letter === 'Ő') return 'O';
        if (letter === 'Ú' || letter === 'Ü' || letter === 'Ű') return 'U';
        return letter;
    };

    const getFilteredList = () => {
        if (!letter && term === '')
            return _data.glossaryList;
        else {
            let arr = [];
            for (let i = 0; i < _data.glossaryList.length; i++) {
                const title = getValueFromParameterMap(_data.glossaryList[i], 'title');
                if (letter) {
                    if (letter.toUpperCase() === getLatin(title[0].toUpperCase())) arr.push(_data.glossaryList[i]);
                } else if (term !== '') {
                    if (title.toUpperCase().indexOf(term.toUpperCase()) > -1) arr.push(_data.glossaryList[i]);
                }
            }
            return arr;
        }
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page); }} className='w-full flex flex-col items-center'>

            <PageTitle
                className='py-page-title-small-offset'
                title={_data.title}
                subTitle={_data.subTitle}
                splitClassName='self-stretch lg:w-96 xl:w-64 xl:self-stretch'
                disableContentPadding
                contentChildren={
                    <div className='flex-1 w-full h-full flex flex-col xl:items-end xl:justify-end'>
                        <div className='w-full relative'>
                            <div className='absolute right-0 top-0 h-full aspect-square flex items-center justify-center'>
                                <HiOutlineMagnifyingGlass className='text-xl' />
                            </div>
                            <input className='w-full line-input-white' placeholder='Keresés' value={term} onChange={(e) => setTerm(e.target.value)} />
                        </div>
                    </div>
                }
                bottomChildren={
                    <div className='w-full flex flex-col items-start gap-10'>

                        <div className='w-full grid grid-cols-9 sm:flex flex-wrap gap-1'>

                            {letters.map((item) => <button onClick={() => letterAvailable(item) && toggleLetter(item)} className={'flex-1 flex items-center justify-center aspect-square transition-colors ' + (letterAvailable(item) ? (isActive(item) ? 'bg-accent' : 'bg-primary-dark') : '')} key={item}>

                                <div className={'text-sm sm:text-xs lg:text-sm xl:text-base transition-colors ' + (isActive(item) ? 'text-primary font-medium' : 'text-white')}>{item}</div>

                            </button>)}

                        </div>

                    </div>
                }
            />

            <div className='flex-1 w-full bg-neutral-50 flex flex-col items-center px-site-mobile md:pb-16 text-primary'>

                <div className={'w-full max-w-boxed px-content page-title-content-offset'}>

                    <div className='flex flex-col gap-8'>
                        {getFilteredList() && getFilteredList().map((item) => <Item key={item.key} data={item} />)}
                    </div>

                </div>

            </div >

        </NBossCMSPage >
    );
}

function Item(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        content: getValueFromParameterMap(props.data, 'content'),
    }

    return (
        <div className='w-full flex flex-col gap-8 p-8 bg-white shadow-3xl'>
            <div className='text-2xl 2xl:text-4xl font-display'>{_data.title}</div>
            <div className='flex-1 text-xs xl:text-sm paragraph-gap leading-relaxed' dangerouslySetInnerHTML={{ __html: _data.content }} />
        </div>
    );
}