import axios from 'axios';

export const getSubFolders = (id, cb) => {
    if (id) {
        const url = 'https://www.diofaalapkezelo.hu/backings/diofaFileRepositoryFolderControl/getSubFolders'
        const body = { 'folderId': id }

        axios.post(url, body).then(function (response) {
            if (response.data && response.data.result) cb(response.data.result);
        }).catch(function (error) {
            console.log(error);
        });
    }
};

export const getFolderFiles = (id, cb) => {
    if (id) {
        const url = 'https://www.diofaalapkezelo.hu/backings/diofaFileRepository/getFolderFiles'
        const body = { 'parentFolderId': id }

        axios.post(url, body).then(function (response) {
            if (response.data && response.data.result) cb(response.data.result);
        }).catch(function (error) {
            console.log(error);
        });
    }
};