import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

import TextChanger from 'elements/TextChanger';

import { getValueFromParameterMap } from 'cms/NBossCMS';

const transitionDuration = 1;
const duration = 100;

export default function Slideshow(props) {
    const [activeIndex, setActiveIndex] = useState(0);

    let getNext = () => { return activeIndex + 1 >= props.data.length ? 0 : activeIndex + 1; }
    let getPrev = () => { return activeIndex - 1 < 0 ? props.data.length - 1 : activeIndex - 1; }

    useEffect(() => {
        if (props.data != null) setActiveIndex(0);
    }, [props.data])

    useEffect(() => {
        let timer = setTimeout(() => { setActiveIndex(getNext()); }, duration * 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    const _data = {

    }

    return (
        <div className={'relative w-full flex flex-col items-center overflow-hidden text-white ' + (props.className || '')} style={{ minHeight: '100dvh' }}>

            {props.data.map((item, index) => (<Slide key={index} data={item} active={activeIndex === index} />))}

            <div className='w-full flex-1 mb-72 pt-36 md:pt-24 pb-12 md:pb-0 flex flex-col md:items-center justify-center z-10'>
                <Content data={props.data[activeIndex]} onNext={() => setActiveIndex(getNext())} onPrev={() => setActiveIndex(getPrev())} />
            </div>

        </div>
    );
}

function Content(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        titleFirstLine: getValueFromParameterMap(props.data, 'title-first-line'),
        titleSecondLine: getValueFromParameterMap(props.data, 'title-second-line'),
        subTitle: getValueFromParameterMap(props.data, 'sub-title'),
        content: getValueFromParameterMap(props.data, 'content'),
        align: getValueFromParameterMap(props.data, 'align'),
        buttonLabel: getValueFromParameterMap(props.data, 'button-label'),
        url: getValueFromParameterMap(props.data, 'url'),
        path: getValueFromParameterMap(props.data, 'path'),
        brand: getValueFromParameterMap(props.data, 'brand'),
    }

    return (
        <div className='w-full px-site flex-1 md:flex-none flex flex-col items-center z-10'>

            <div className='w-full flex-1 max-w-boxed flex items-center px-content'>

                {_data.brand && <div className='relative hidden md:flex w-[250px] h-[250px]'>

                    <div className='shadow-2xl w-[10000px] h-[10000px] absolute -top-[8466px] -left-[8800px] bg-primary-dark -rotate-[27.5deg]' />

                    <div className='absolute  w-full h-full bg-contain bg-no-repeat' style={{ backgroundImage: 'url(/assets/images/logo/initial-accent.svg)' }} />

                </div>}

                <div className='w-full flex-1 max-w-boxed flex flex-col justify-between md:justify-start md:gap-20 px-content bg-yellow-4000'>

                    <div className='flex flex-col justify-center gap-6'>

                        {!_data.brand && <div className={'font-display  leading-none flex flex-col justify-center text-[48px] md:text-[90px]'}>
                            <TextChanger direction='up' text={_data.titleFirstLine} />
                            <TextChanger direction='down' className='text-accent' text={_data.titleSecondLine} />
                        </div>}

                        {_data.brand && <div className={' flex flex-col gap-2 mt-8'}>
                            <TextChanger direction='up' text={_data.content} />
                            <div className='font-display leading-none flex flex-col gap-2 justify-center text-[24px] md:text-[38px]'>
                                <TextChanger direction='up' text={_data.titleFirstLine} />
                                <TextChanger direction='down' className='text-accent' text={_data.titleSecondLine} />
                            </div>
                        </div>}

                        {!_data.brand && <SubTitle className='flex md:hidden' subTitle={_data.subTitle} content={_data.content} />}

                    </div>

                    <div className='w-full flex items-center'>

                        <SubTitle className={'hidden md:flex text-xl self-stretch ' + (_data.brand ? 'opacity-0' : 'opacity-100')} subTitle={_data.subTitle} content={_data.content} />

                        <div className='w-full md:w-auto flex gap-4'>
                            <a href={_data.path ? _data.path : _data.url} className='rounded-button-accent flex-1'>{_data.buttonLabel || 'Bővebben'}</a>
                            <button className='rounded-icon-button-accent' onClick={props.onPrev}><HiOutlineChevronLeft /></button>
                            <button className='rounded-icon-button-accent' onClick={props.onNext}><HiOutlineChevronRight /></button>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
}

function SubTitle(props) {
    return (
        <div className={'w-full flex-col justify-center transition-all bg-red-4000 ' + (props.className || '')}>

            <TextChanger className='font-semibold' direction='up' text={props.subTitle || ' '} />
            <TextChanger direction='down' text={props.content} />

        </div>
    );
}

function Slide(props) {
    const _data = {
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        fade: getValueFromParameterMap(props.data, 'fade'),
        align: getValueFromParameterMap(props.data, 'align'),
        brand: getValueFromParameterMap(props.data, 'brand'),
    }

    // const getAlignClassName = (align) => {
    //     const classNameMap = {
    //         'LEFT': {
    //             'gradient': 'bg-gradient-to-r from-black/75',
    //             'text': 'text-left',
    //             'container': 'items-start'
    //         },
    //         'RIGHT': {
    //             'gradient': 'bg-gradient-to-l from-black/75',
    //             'text': 'text-right',
    //             'container': 'items-end'
    //         },
    //         'CENTER': {
    //             'gradient': 'bg-gradient-to-r from-transparent via-black/75 to-transparent',
    //             'text': 'text-center',
    //             'container': 'items-center'
    //         },
    //     };
    //     return classNameMap[align] ? classNameMap[align] : classNameMap['CENTER'];
    // };

    // const buttonClassName = twMerge(getClassName('generic-button'), getClassName('generic-button-white-opacity'));

    return (
        <motion.div
            className={'absolute w-full h-full text-white bg-primary overflow-hidden ' + (props.active ? 'pointer-events-auto' : 'pointer-events-none')}
            transition={{ duration: transitionDuration }}
            initial={props.active ? 'visible' : 'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1 }, 'hidden': { opacity: 0 } }}>


            <div className='relative w-full h-full flex items-center justify-center'>

                <motion.div
                    className={'absolute w-full h-full bg-center bg-no-repeat bg-cover grayscale ' + (_data.brand ? 'brightness-75 contrast-75' : 'brightness-125 contrast-125')}
                    style={{ backgroundImage: 'url(' + _data.image + ')' }}
                    transition={{ ease: 'linear', duration: duration }}
                    initial={'start'}
                    animate={props.active ? 'finish' : 'start'}
                    variants={{ 'start': { scale: 1.1, rotate: 0.25, transition: { duration: transitionDuration } }, 'finish': { scale: 1, rotate: 0 } }}>

                    {/* {_data.fade && <div className={twMerge('absolute w-full h-full z-10', getAlignClassName(_data.align).gradient)} />} */}

                </motion.div>

                <div className='absolute w-full h-full bg-primary-light mix-blend-multiply'></div>

            </div>

        </motion.div>
    );
}