import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

import Slideshow from 'components/Slideshow';
import LatestNews from 'components/LatestNews';
import LatestPublications from 'components/LatestPublications';
import { PremiumContactRow, PremiumContactFloater } from 'components/PremiumContact';
import LatestJobs from 'components/LatestJobs';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function MainPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    const _data = {
        slideshow: getValueFromParameterMap(page, 'slideshow') && getValueFromParameterMap(getValueFromParameterMap(page, 'slideshow'), 'slide-list').contentList,
        serviceList: getValueFromParameterMap(page, 'service-list') && getValueFromParameterMap(getValueFromParameterMap(page, 'service-list'), 'service-list').contentList,
        newsList: getValueFromParameterMap(page, 'news-list') && getValueFromParameterMap(page, 'news-list').contentList,
        realEstateDataList: getValueFromParameterMap(page, 'real-estate-data-list') && getValueFromParameterMap(getValueFromParameterMap(page, 'real-estate-data-list'), 'real-estate-data-list').contentList,
        jobList: getValueFromParameterMap(page, 'job-list') && getValueFromParameterMap(page, 'job-list').contentList,
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col'>

            {_data.slideshow && <Slideshow data={_data.slideshow} />}

            {_data.serviceList && <Services data={_data.serviceList} />}

            {_data.newsList && <LatestNews data={_data.newsList} />}

            {page && <LatestPublications />}

            {page && <PremiumContactRow />}

            {_data.realEstateDataList && <RealEstatePortfolio data={_data.realEstateDataList} />}

            {_data.jobList && <LatestJobs data={_data.jobList} />}

            <PremiumContactFloater />

        </NBossCMSPage>
    );
}

function Services(props) {
    return (
        <div className='w-full flex flex-col items-center px-site-mobile z-10'>

            <div className='w-full max-w-boxed flex flex-col gap-32 text-white bg-gradient-to-b from-accent/20 via-secondary/95 to-secondary md:rounded-md backdrop-blur shadow-3xl py-16 px-16 xl:pl-28 sm:pr-16 -mt-72'>

                <div className='w-full font-display text-3xl sm:text-5xl'>Befektetési szolgáltatásaink</div>

                <div className='flex flex-col md:flex-row gap-20 pl-4 md:pl-4 lg:pl-10'>
                    {props.data && props.data.map((item) => (<ServiceItem key={item.id} data={item} />))}
                </div>

            </div>

        </div>
    );
}

function ServiceItem(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        content: getValueFromParameterMap(props.data, 'content'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        url: getValueFromParameterMap(props.data, 'url'),
        path: getValueFromParameterMap(props.data, 'path'),
    }

    return (
        <div className='w-full flex flex-col items-start justify-between gap-12'>

            <div className='relative flex flex-col gap-5'>

                <div className='absolute top-0 left-0 w-0 h-0'>
                    <div className='relative mt-8 lg:mt-12 -mr-5 lg:-mr-8'>
                        <div className='absolute bottom-0 right-0 w-16 lg:w-20 xl:w-24 bg-contain aspect-[320/416]' style={{ backgroundImage: 'url(' + _data.image + ')' }} />
                    </div>
                </div>

                <div className='md:w-min font-display text-4xl md:text-xl lg:text-3xl xl:text-4xl'>{_data.title}</div>
                <div className='text-xs lg:text-sm opacity-50'>{_data.content}</div>
            </div>

            {_data.url && <a className='rounded-button-white' href={_data.url}>Részletek</a>}
            {_data.path && <Link className='rounded-button-white' to={_data.path}>Részletek</Link>}

        </div>
    );
}

function RealEstatePortfolio(props) {
    return (
        <div className='w-full flex flex-col items-center px-site bg-neutral-50 border-b-8 border-accent'>

            <div className='w-full max-w-boxed px-content py-16 flex flex-col gap-10'>

                <div className='flex flex-col gap-2'>
                    <div className='w-full font-display text-4xl sm:text-5xl text-primary-dark'>Grandum Real Estate</div>
                    <div className='w-full font-display text-3xl sm:text-4xl text-accent-medium'>Ingatlanportfóliónk</div>
                </div>

                <div className='flex flex-col sm:grid sm:grid-cols-2 md:flex md:flex-row items-start'>
                    {props.data && props.data.map((item) => (<RealEstatePortfolioItem key={item.id} data={item} />))}
                </div>

                <a href='https://grandum.hu/' className='icon-button-secondary'>Tovább a Grandum weboldalára<FiArrowRight /></a>

            </div>

        </div>
    );
};

function RealEstatePortfolioItem(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        value: getValueFromParameterMap(props.data, 'value'),
    }

    return (
        <div className='flex flex-col gap-0.5 py-4 md:px-8 first:md:pl-0 last:md:pr-0 border-b sm:border-b-0 md:border-r last:border-r-0 border-primary/40'>

            <div className='text-7xl md:text-5xl xl:text-7xl text-accent-medium font-medium'>{_data.value}</div>
            <div className='text-base md:text-sm xl:text-base text-secondary font-semibold'>{_data.title}</div>

        </div>
    );
};