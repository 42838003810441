import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import Modal from 'elements/Modal';

export default function CookieModal(props) {
    const [visible, setVisible] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [active, setActive] = useState([]);

    const cookieName = 'granit-cookie-consent';
    const cookieLink = 'https://granitalapkezelo.hu/adatvedelem';

    const options = [
        {
            title: 'A weboldal működéséhez elengedhetetlenül szükséges sütik',
            description: 'A weboldal technikai működéséhez (pl. a grafikai tartalmak hibátlan megjelenítéséhez) feltétlenül szükségesek, ezért kikapcsolásukra nincs lehetőség, ugyanakkor semmilyen, személyes adatnak minősülő információt nem gyűjtenek.',
            key: 'MANDATORY',
            disabled: true
        },
        {
            title: 'Statisztikai sütik',
            description: 'A weboldal látogatottságának, valamint a felhasználók weboldalon kifejtett aktivitásának mérésére szolgálnak. Elősegítik a felhasználói élmény javítását, a zavartalan és megfelelő színvonalú szolgáltatásnyújtás biztosítását. A statisztikai sütikkel gyűjtött információk név nélkül kerülnek tárolásra és a látogatók viselkedésének anonim elemzésére kerülnek felhasználásra.',
            key: 'STATISTICS'
        }
    ];

    useEffect(() => {
        let mandatory = [];
        for (let i = 0; i < options.length; i++) if (options[i].disabled) mandatory.push(options[i].key);
        setActive(mandatory);

        const cookieConsent = localStorage.getItem(cookieName)
        let showPopup = cookieConsent ? false : true;
        if (showPopup) setTimeout(() => setVisible(true), 3000);
    }, []);

    const save = (keyList) => {
        const cookie = keyList.toString() + '@' + Date.now();
        localStorage.setItem(cookieName, cookie);
        setVisible(false);
    };

    const handleActiveChange = (key) => {
        let _active = [...active];
        if (active.indexOf(key) === -1) _active.push(key);
        else _active.splice(_active.indexOf(key), 1)
        setActive(_active);
    };

    const saveAll = () => {
        let keys = [];
        for (let i = 0; i < options.length; i++) keys.push(options[i].key);
        save(keys);
    };

    const saveSettings = () => {
        save(active);
    };

    if (!visible)
        return null;
    else
        return (
            <Modal className='flex flex-col gap-8 flex-1 overflow-y-auto' onClose={() => setVisible(false)}>

                <div className='font-display text-4xl'>Adatkezelési beállítások</div>

                <div className='flex flex-col gap-6 text-sm'>
                    <div className=''>Weboldalunk az alapvető működéshez szükséges cookie-kat használ. Szélesebb körű funkcionalitáshoz (marketing, statisztika, személyre szabás) egyéb cookie-kat engedélyezhet. Részletesebb információkat az Adatkezelési tájékoztatóban talál.</div>
                    <div className=''>Ha szeretne többet megtudni többet a sütik használatáról, <a href={cookieLink} className='font-bold text-accent hover:text-white transition-colors'>olvassa el a tájékoztatónkat.</a></div>
                </div>

                {settingsOpen && <div className='bg-secondary p-8 -mx-8 flex flex-col gap-6'>
                    {options.map((item, index) => (index < 3 && <Option active={active.indexOf(item.key) > -1} onActiveChange={() => handleActiveChange(item.key)} key={item.key} data={item} />))}
                </div>}

                <div className='w-full flex flex-col md:flex-row gap-4 md:gap-6'>
                    <button className='flex-1 rounded-button-accent' onClick={() => settingsOpen ? saveSettings() : setSettingsOpen(!settingsOpen)}>{settingsOpen ? 'Beállítások mentése' : 'Beállítások mósosítása'}</button>
                    <button className='flex-1 rounded-button-accent-reverse' onClick={() => saveAll()}>Mindet elfogadom</button>
                </div>

            </Modal>
        );
}

function Option(props) {
    return (
        <div className='flex flex-col gap-2'>

            <button className='flex items-center gap-3 md:gap-2 group text-left' onClick={() => props.onActiveChange()} disabled={props.data.disabled}>
                <Toggle active={props.active} />
                <div className='text-sm font-semibold'>{props.data.title}</div>
            </button>

            <div className='text-[10px]'>{props.data.description}</div>

        </div>
    );
}

function Toggle(props) {
    return (
        <div className={'relative h-4 aspect-[2/1] rounded-full overflow-hidden shadow-inner group-disabled:opacity-50 flex flex-col transition-colors ' + (props.active ? 'bg-accent/10' : 'bg-white/20')}>

            <div className={'absolute left-0 h-full aspect-square rounded-full shadow bg-white transition-all ' + (props.active ? 'scale-0' : 'scale-100')}></div>
            <div className={'absolute right-0 h-full aspect-square rounded-full shadow bg-accent transition-all ' + (props.active ? 'scale-100' : 'scale-0')}></div>

        </div>
    );
}