import { useState, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'
import { motion } from 'framer-motion';
import { HiOutlineChevronDown } from 'react-icons/hi';

import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';
import useWindowSize from 'hooks/useWindowSize';
import { useWebsite } from 'providers/WebsiteContextProvider';
import { getUrlOrPathForMenuItem } from 'utils/GenericUtils';

export default function Header(props) {
    const [width] = useWindowSize();
    const [scroll, setScroll] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const location = useLocation();
    const { lightHeader } = useWebsite();

    let _data = {
        menu: getValueFromParameterMap(getWebsite().globalContent, 'main-menu'),
    };

    useEffect(() => {
        const onScroll = () => { setScroll(window.pageYOffset > 0 ? true : false); };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => { if (width > 1024) setMobileMenuOpen(false); }, [width]);

    useEffect(() => { setMobileMenuOpen(false); }, [location])

    return (
        <header className={'fixed top-0 z-50 w-full flex items-center justify-between px-site transition-all ' + ((scroll && !mobileMenuOpen) ? ('h-16 ' + (lightHeader ? 'bg-primary/95 text-white' : ' bg-secondary/95 text-white shadow-3xl')) : ('h-24 ' + ((lightHeader ? 'text-primary' : 'text-white')))) + ' ' + (props.className || '')}>

            <Link className={'z-10 relative transition-all ' + ((scroll && !mobileMenuOpen) ? 'h-[32px]' : 'h-[45px] sm:h-[32px] xl:h-[45px]')} to='/'>
                {/* <Link className={'z-10 relative transition-all ' + ((scroll && !mobileMenuOpen) ? 'w-[100px]' : 'w-[140px] sm:w-[100px] xl:w-[140px]')} to='/'> */}

                {process.env.REACT_APP_DOMAIN_NAME === 'premium-vagyonkezeles.hu' ?
                    <>
                        <img alt='Gránit Prémium Vagyonkezelés' className='h-full' src='/assets/images/logo/logo-white-premium.svg' />
                        <img alt='Gránit Prémium Vagyonkezelés' className={'absolute w-full h-full top-0 left-0 transition ' + ((!scroll && !mobileMenuOpen && lightHeader) ? 'opacity-100' : 'opacity-0')} src='/assets/images/logo/logo-primary.svg' />
                    </> : <>
                        <img alt='Gránit Alapkezelő' className='h-full' src='/assets/images/logo/logo-white.svg' />
                        <img alt='Gránit Alapkezelő' className={'absolute w-full h-full top-0 left-0 transition ' + ((!scroll && !mobileMenuOpen && lightHeader) ? 'opacity-100' : 'opacity-0')} src='/assets/images/logo/logo-primary.svg' />
                    </>}

            </Link>

            <DesktopMenu className='hidden lg:flex' data={_data.menu} />

            <div className='lg:hidden z-10 -mr-[10px]' >
                <Hamburger size={28} color={lightHeader ? '#015359' : '#ffffff'} direction='left' rounded toggled={mobileMenuOpen} toggle={() => setMobileMenuOpen(!mobileMenuOpen)} />
            </div>

            <MobileMenu className={'transition duration-300 ' + (mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none')} open={mobileMenuOpen} data={_data.menu} />

        </header>
    );
}

function DesktopMenu(props) {
    return (
        <div className={'h-16 flex-row gap-6 2xl:gap-10 ' + (props.className || '')}>

            {props.data && props.data.itemList.map((item) => (<DesktopMenuItem key={item.id} data={item} />))}

        </div>
    );
}

function DesktopMenuItem(props) {
    const [open, setOpen] = useState(false);

    const { lightHeader } = useWebsite();

    const buttonClassName = 'h-full flex items-center justify-center';
    const buttonLabelClassName = 'text-center text-[12px] xl:text-[12px] 2xl:text-[12px] font-semibold xl:tracking-wider uppercase transition antialiased ' + (lightHeader ? 'group-hover:text-primary-dark' : 'group-hover:text-accent');

    return (
        <div className='relative h-full flex flex-col items-center group' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>

            {getUrlOrPathForMenuItem(props.data).url ?
                <a href={getUrlOrPathForMenuItem(props.data).url} className={buttonClassName}><div className={buttonLabelClassName + (lightHeader ? ' text-secondary' : '')}>{props.data.title}</div></a>
                : <Link to={getUrlOrPathForMenuItem(props.data).path} className={buttonClassName}><div className={buttonLabelClassName}>{props.data.title}</div></Link>}

            {props.data.childList && <div className='absolute min-w-full bottom-0 h-0'>
                <motion.div
                    className={'flex flex-col items-center min-w-full px-2 backdrop-blur-sm border-t-4 ' + (lightHeader ? 'bg-primary/95 border-primary-dark' : 'bg-secondary/95  border-accent')}
                    initial={open ? 'visible' : 'hidden'}
                    animate={open ? 'visible' : 'hidden'}
                    variants={{ visible: { opacity: 1, y: 0, display: 'flex' }, hidden: { opacity: 0, y: 10, transitionEnd: { display: 'none' } } }}
                    transition={{ duration: animationDuration }}>

                    {props.data && props.data.childList.map((item) => (<DesktopSubItem key={item.id} data={item} />))}

                </motion.div>
            </div>}

        </div>
    );
}

function DesktopSubItem(props) {
    const className = 'w-max border-b last:border-none border-white/10 first:pt-1 last:pb-1 px-4 ' + (props.className || '');
    const labelClassName = 'text-[10px] text-center font-medium uppercase transition py-3 ';

    return (
        <>
            {getUrlOrPathForMenuItem(props.data).url ?
                <a href={getUrlOrPathForMenuItem(props.data).url} className={className + ' text-white hover:text-accent'}><div className={labelClassName}>{props.data.title}</div></a>
                : <NavLink to={props.data.fullPath} className={className} end>
                    {({ isActive }) => (<div className={labelClassName + (isActive ? 'text-accent' : 'text-white hover:text-accent')}>{props.data.title}</div>)}
                </NavLink>}
        </>
    );

    // return (
    //     <NavLink to={props.data.fullPath} className={'w-max border-b last:border-none border-white/10 first:pt-1 last:pb-1 px-4 ' + (props.className || '')} end>
    //         {({ isActive }) => (<div className={'text-[10px] text-center font-medium uppercase transition py-3 ' + (isActive ? 'text-accent' : 'text-white hover:text-accent')}>{props.data.title}</div>)}
    //     </NavLink>
    // );
}

const animationDuration = 0.3;
const getStager = (index, length) => { return (animationDuration / length) * index; };

function MobileMenu(props) {
    return (
        <div className={'fixed top-0 left-0 w-screen h-full bg-gradient-to-b from-primary/95 to-primary-darker/95 backdrop-blur-sm flex flex-col justify-end ' + (props.className || '')}>

            <div className='absolute top-0 left-0 w-full h-24 bg-gradient-to-b from-primary/95 via-primary/95 z-10' />

            <div className='w-full max-h-full overflow-y-scroll overflow-x-hidden hidden-scrollbar'>

                <div className='w-full flex flex-col gap-6 px-12 pt-24 pb-12'>

                    {props.data && props.data.itemList.map((item, index) => (
                        <motion.div
                            className='w-full flex'
                            key={item.id}
                            initial={props.open ? 'visible' : 'hidden'}
                            animate={props.open ? 'visible' : 'hidden'}
                            variants={{ visible: { opacity: 1, x: 0 }, hidden: { opacity: 0, x: 120 } }}
                            transition={{ duration: animationDuration, type: 'spring', stiffness: 80, delay: getStager(index, props.data.itemList.length) }}>

                            <MobileMainItem data={item} />

                        </motion.div>
                    ))}

                </div>

            </div>

        </div>
    );
}

function MobileMainItem(props) {
    const [open, setOpen] = useState(false);

    const buttonClassName = 'flex-1 flex';
    const buttonLabelClassName = 'w-full text-right font-semibold tracking-widest uppercase';

    return (
        <div className='w-full flex flex-col overflow-hiddenn'>

            <div className='w-full flex gap-4 items-center py-2'>

                {getUrlOrPathForMenuItem(props.data).url ?
                    <a href={getUrlOrPathForMenuItem(props.data).url} className={buttonClassName}><div className={buttonLabelClassName + ''}>{props.data.title}</div></a>
                    : <NavLink to={getUrlOrPathForMenuItem(props.data).path} className={buttonClassName}>{({ isActive }) => (<div className={buttonLabelClassName + ' ' + (isActive ? 'text-accent' : 'text-white')}>{props.data.title}</div>)}</NavLink>}

                {/* <NavLink to={props.data.fullPath} className='flex-1 flex'>
                    {({ isActive }) => (<span className={'w-full text-right font-semibold tracking-widest uppercase ' + (isActive ? 'text-accent' : 'text-white')}>{props.data.title}</span>)}
                </NavLink> */}

                {props.data.childList && <div className={'w-6 aspect-square rounded-full flex items-center justify-center border border-accent transition ' + (open ? 'bg-accent' : '')} onClick={() => setOpen(!open)}>
                    <HiOutlineChevronDown className={'text-sm transition ' + (open ? 'rotate-180 text-primary' : 'rotate-0 text-accent')} />
                </div>}

            </div>

            {props.data.childList && <motion.div
                className='flex flex-col gap-2'
                initial={open ? 'visible' : 'hidden'}
                animate={open ? 'visible' : 'hidden'}
                variants={{ visible: { height: 'auto' }, hidden: { height: 0 } }}
                transition={{ duration: animationDuration, delay: (open ? 0 : animationDuration) }}>

                {props.data && props.data.childList.map((item, index) => (
                    <motion.div
                        className='w-full flex'
                        key={item.id}
                        initial={open ? 'visible' : 'hidden'}
                        animate={open ? 'visible' : 'hidden'}
                        variants={{ visible: { opacity: 1, x: 0 }, hidden: { opacity: 0, x: 60 } }}
                        transition={{ duration: animationDuration, type: 'spring', stiffness: 150, delay: (open ? animationDuration : 0) + getStager(index, props.data.childList.length) }}>

                        <MobileSubItem className='first:mt-2' data={item} />

                    </motion.div>
                ))}

            </motion.div>}

        </div>
    );
}

function MobileSubItem(props) {
    const className = 'w-full flex ' + (props.className || '');
    const labelClassName = 'w-full text-sm text-right tracking-widest ';

    return (
        <>
            {getUrlOrPathForMenuItem(props.data).url ?
                <a href={getUrlOrPathForMenuItem(props.data).url} className={className + ' text-white'}><span className={labelClassName}>{props.data.title}</span></a>
                : <NavLink to={props.data.fullPath} className={className} end>
                    {({ isActive }) => (<span className={labelClassName + (isActive ? 'text-accent' : 'text-white')}>{props.data.title}</span>)}
                </NavLink>}
        </>
    );

    return (
        <NavLink to={props.data.fullPath} className={'w-full flex ' + (props.className || '')}>
            {({ isActive }) => (<span className={'w-full text-sm text-accent text-right tracking-widest ' + (isActive ? 'text-accent' : 'text-white')}>{props.data.title}</span>)}
        </NavLink>
    );
}